import { makeStyles, Modal } from '@material-ui/core';
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { modalConfirmReject, modalReject } from '../../../../redux/actions/CircuitoFirmasAction';

const useStyle = makeStyles({
    modal: {
        margin:0,
        padding:"40px",
        position: "absolute",
        backgroundColor: "white",
        display: "flex",
        flexDirection:"column",
        alignItems: "center",
        boxShadow: '0px 3px 0px #00000033 !important',
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        borderRadius: '5.72px',
        width: "530px",
        height: "308px",
        maxWidth: "1050px",
        "&:focus": {
            outline: "none"
        }
    },
    title:{
        color: '#E55200', 
        fontSize: "24px", 
        fontFamily: "Mulish",
        fontWeight: "700", 
        margin:0,
    }, 
    parrafo:{
        fontSize:"16px"
    },
    btnCerrar: {
        color: "#E55200",
        padding: "8px 24px",
        fontSize: "16px",
        cursor: "pointer",
        border: "1px solid #E55200",
        borderRadius: "8px",
        backgroundColor: "transparent",
        fontFamily: "Mulish",
        fontWeight: "700",
        margin: 0,
    },
    continue: {
        backgroundColor: "#E55200",
        color: "white",
        padding: "8px 24px",
        fontSize: "16px",
        display:"flex",
        alignItems:"center",
        cursor: "pointer",
        fontWeight: "800",
        fontFamily: "Mulish",
        border: "1px solid #E55200",
        borderRadius: "8px",
        "&:hover": {
            backgroundColor: "#E55200",
        },
    },
    flex:{
        display: "flex",
        justifyContent:"center", 
        alignItems:"center",
        gap: "16px",
    }
})

const ModalReject = (props) => {
    const dispatch = useDispatch()
    const classes = useStyle();

    const cerrar = ()=> {
        dispatch(modalReject(false))
    }

    const continuar=()=> {
        dispatch(modalReject(false))
        dispatch(modalConfirmReject(true))
    }
    return (
        <Modal
            open={true}
            // onClose={closeModal}
             disableBackdropClick
            >  
           <div className={classes.modal}>
                <h4 className={classes.title}>¡Vas a rechazar!</h4>
                <p className={classes.parrafo}>Ten presente que al rechazar se anulará este circuito de firmas. </p>
                <p className={classes.parrafo}>Antes de hacerlo puedes contactar al creador del circuito <b>{props.correoCreador}</b> y resolver tus dudas</p>
                <div className={classes.flex}>
                    <button className={classes.btnCerrar} onClick={()=> cerrar()}>Cerrar</button>
                    <button className={classes.continue} onClick={()=> continuar()} >Continuar <ArrowForwardIosIcon style={{fontSize:"16px"}}/> </button>
                </div>
           </div>
        </Modal>
    )
}

export default ModalReject