import React, { useState, useEffect } from 'react';
import ButtonFiles from '../../components/ButtonFiles';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Paper, Button, Container, Grid, Box} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ZoneUpload from '../../components/ZoneUploadLocal';
import InfoValidation from './InfoValidation';
import Loading from '../../components/Loading';
import { ACCEPT_FILES_VALIDATE } from '../../redux/types/ApiConstants';
import { getInfoValidateAction } from '../../redux/actions/ValidateDocumentAction';
import { MAX_MEGAS_VALIDATE } from '../../redux/types/ApiConstants';
import { toBase64 } from '../../helpers/FilesFunction';
import { getListSignature } from '../../helpers/SignatureUtil';
 
const useStyles = makeStyles((theme) => ({
	titleBackground: {
		margin: '0',
		background: 'rgba(0, 0, 0, 0.3)',
		height: '100px',
		paddingTop: '20px',
		width: '100%',
	},
	message: {
		textAlign: 'right',
		color: 'white',
		marginTop: 10,
	},
	root: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	paper: {
		padding: '35px',
		marginTop: '-25px',
		borderRadius: '10px',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #F5AE19',
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	gridItem: {
		margin: '15px 5px 0',
		marginLeft: '20px',
		justifyItems: 'right;'
	},
	gridItem: {
		margin: '15px 5px 0',
		marginLeft: '20px',
		justifyItems: 'right;'
	},
}));

const ValidateDocument = (props) => {
	const StepsEnum = { LoadFile: 1, ListToValidate: 2, InfoValidated: 3 };
	const [dataDocumentPdf, setDataDocument] = useState(null);
	const [isResponsive, setResponsive] = useState(window.innerWidth <= 600 ? false : true);
	const { getInfoValidate, validate } = props;
	const { dataDocument } = validate;
	const [loading, setLoading] = useState(false);
	const [files, setFiles] = useState([]);
	const [stepActive, setStepActive] = useState(StepsEnum.LoadFile);
	const { enqueueSnackbar } = useSnackbar();

	const handleClickVerify = async (e) => {
		if(files[0].name.slice(-4) == '.pdf'){
			const base64Documento = (await toBase64(files[0])).split(',').pop();
			var dataDocumentPdfDatos = {"codigoRespuesta":"RS1", "httpStatus":200,
			"documentoFirmado": getListSignature(base64Documento, true), "mensaje":"Archivo validado con exito"};
			if (dataDocumentPdfDatos.documentoFirmado.length > 0 )setDataDocument(dataDocumentPdfDatos);
			else {
				enqueueSnackbar(`El archivo no contiene firmantes`, {
					variant: 'warning',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
				})
				return;
			}
		}else {
			setDataDocument(null);
			setLoading(true);
			await getInfoValidate(files[0], enqueueSnackbar);
			setLoading(false);
		}
		setStepActive(StepsEnum.InfoValidated);
	};

	const handleClickRegresar = () => {
		setStepActive(StepsEnum.LoadFile);
	};

	window.addEventListener('resize', function (event) {
		if (this.window.innerWidth <= 600) setResponsive(false);
		else setResponsive(true);
	}, true);

	useEffect(() => {
		document.body.className = 'bodyMiddle';
	});

	document.body.className = 'bodyMiddle';

	const classes = useStyles();
	return (
		<>
			{loading && <Loading />}
			<Grid container spacing={0} direction="row">
				<Container component="main" maxWidth="lg">
					<h1 id={stepActive === StepsEnum.InfoValidated && 'titleValidate'} className={'title-background'}>{isResponsive ? 'Verificar documentos' : 'Verificar otros documentos'}</h1>
				</Container>
				<Container component="main">
					<Grid
						container
						spacing={0}
						direction="row"
						justify="flex-start"
						alignItems="flex-start"
						className="zone-upload-container"
						maxWidth="md"
					>
						<Grid item xs={12} sm={12} md={12} lg={12}>
							<Paper elevation={3} id={ stepActive === StepsEnum.InfoValidated && isResponsive && 'paper-upload-result' } className="paper-upload" >
								{stepActive !== StepsEnum.InfoValidated && (
									<>
										<ZoneUpload
											accept={ACCEPT_FILES_VALIDATE}
											isResponsive={isResponsive}
											setFiles={setFiles}
											files={files}
											maxFiles={1}
											multiple={false}
											maxSize={(MAX_MEGAS_VALIDATE * 1000000)}
										/>

											<>
												<div id="box-botonera" style={{ marginTop: '28px' }}>
													<Grid
														container
														className='gridValidate'
														spacing={2}
													>
														<Grid item style={{visibility: Boolean(files.length) ? 'visible' : 'hidden' }}>
															<ButtonFiles
																accept={ACCEPT_FILES_VALIDATE}
																modeChange={true}
																multiple={false}
																files={files}
																setFiles={setFiles}
																setLoading={setLoading}
																maxSize={(MAX_MEGAS_VALIDATE * 1000000)}/>
														</Grid>
														<Grid item>
															<Button
																id={'validate'}
																className={'btnValidate'}
																disabled={!Boolean(files.length)}
																disableElevation
																onClick={(e) => { handleClickVerify(e); }}
															>Verificar</Button>
														</Grid>
													</Grid>
												</div>
											</>
										
									</>
								)}
								{stepActive === StepsEnum.InfoValidated && (
									<InfoValidation
										dataDocument={dataDocumentPdf != null ? dataDocumentPdf : dataDocument}
										isPdf={dataDocumentPdf != null}
										isResponsive={isResponsive}
										file={files[0]}
										handleRegresar={handleClickRegresar}
									/>
								)}
							</Paper>
						</Grid>
					</Grid>
				</Container>
			</Grid>
		</>
	);
};

const mapStateToProps = (state) => ({
	validate: state.validate,
});

const mapDispatchToProps = {
	getInfoValidate: getInfoValidateAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ValidateDocument);
