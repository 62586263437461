import React, { useEffect, useState, useRef } from 'react';
import {
	Box,
	Button,
	Checkbox,
	makeStyles,
	MenuItem,
	Modal,
	Select,
	TextField,
	Tooltip,
} from '@material-ui/core';
import { v1 } from 'uuid';
import close from '../../../../assets/img/close-sign.png';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import del from '../../../../assets/img/trashOrange.svg';
import add from '../../../../assets/img/addUser.svg';
import { useDispatch, useSelector } from 'react-redux';
import VisibilityIcon from '@material-ui/icons/Visibility';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {
	firm_positions,
	modalAddFirmantes,
	stepCircuit,
} from '../../../../redux/actions/CircuitoFirmasAction';
import clsx from 'clsx';
import { validateEmail } from '../../../../redux/actions/Validaciones';
import { validateName } from '../../../../redux/actions/Validaciones';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(theme => ({
	select: {
		'&.Mui-disabled': {
			boxShadow: 'none',
			border: '1px solid #D3D2D7',
		},
	},
	modal: {
		position: 'absolute',
		overflowY: 'auto',
		backgroundColor: 'white',
		boxShadow: '0px 3px 0px #00000033 !important',
		padding: theme.spacing(2, 4, 3),
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		borderRadius: '10px !important',
		width: '1024px',
		maxHeight: '650px',
		overflow: 'hidden',
		'&:focus': {
			outline: 'none',
		},
		'@media(max-width:1100px)': {
			width: '900px',
			height: '553px',
		},
		'@media(max-width:950px)': {
			width: '700px',
			height: '553px',
		},
		'@media(max-width:700px)': {
			width: '500px',
			height: '553px',
		},
		'@media(max-width:500px)': {
			width: '400px',
			height: '553px',
		},
	},
	close: {
		justifyContent: 'flex-end',
		marginTop: '-8px',
		marginRight: '-24px',
		float: 'right',
		cursor: 'pointer',
	},
	square: {
		width: '36px',
		minWidth: '36px',
		maxWidth: '36px',
		height: '36px',
		border: '1px solid grey',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: '9px',
		boxShadow: '0 1px 3px 0 rgba(0,0,0,0.46)',
		marginLeft: '15px',
	},
	field: {
		padding: '1px',
		width: '18%',
		border: '1px solid grey',
		borderRadius: '7px',
		boxShadow: '0 1px 3px 0 rgba(0,0,0,0.46)',
		'@media(max-width:1100px)': {
			width: '20%',
			height: '36px',
		},
	},
	fields: {
		width: '212px',
		maxWidth: '212px',
		minWidth: '212px',
		height: '36px',
		border: '1px solid grey',
		borderRadius: '7px',
		overflowX: 'auto',
		boxShadow: '0 1px 3px 0 rgba(0,0,0,0.46)',
		'@media(max-width:1100px)': {
			width: '30%',
			height: '36px',
		},
	},
	containerFirmantes: {
		display: 'flex',
		width: '100%',
		gap: '15px',
		padding: '15px 10px',
		alignItems: 'flex-start',
		cursor: 'move',
		borderBottom: '1px solid #B2B2B2',
		'& button': {
			opacity: '0',
			pointerEvents: 'none',
			padding: '0',
			display: 'inline',
			padding: '0',
			border: 'none',
		},
		'&:hover': {
			border: '1px solid #7B7B7B',
			'& button': {
				opacity: '1',
				pointerEvents: 'auto',
				backgroundColor: 'transparent',
				'&:hover': {
					backgroundColor: '#F1EAEA',
					cursor: 'pointer',
				},
			},
		},
	},
	textField: {
		[`& fieldset`]: {
			borderRadius: '10px',
			border: '1px solid #706F6F',
			boxShadow: '0 1px 3px 0 rgba(0,0,0,0.46)',
		},
		['& input']: {
			'&:-webkit-autofill': {
				'-webkit-text-fill-color': '#000000 !important', // Color del texto
				'-webkit-box-shadow': '0 0 0px 1000px white inset !important', // Fondo del autofill
			},
		},
	},
	containerSignatories: {
		display: 'flex',
		alignItems: 'center',
		width: '960px',
		gap: '15px',
		height: '68px',
		margin: 0,
		justifyContent: 'space-around',
		cursor: 'move',
		borderBottom: '1px solid #B2B2B2',
		'& button': {
			opacity: '0',
			pointerEvents: 'none',
			padding: '0',
			display: 'inline',
			margin: '0 10px 0 16px',
			padding: '0',
			border: 'none',
		},
		'&:hover': {
			border: '1px solid #7B7B7B',
			'& button': {
				opacity: '1',
				pointerEvents: 'auto',
				marginLeft: '-10px',
				backgroundColor: 'transparent',
				'&:hover': {
					backgroundColor: '#F1EAEA',
					cursor: 'pointer',
				},
			},
		},
		'@media(max-width:1100px)': {
			width: '100%',
		},
	},
	containerBtns: {
		display: 'flex',
		justifyContent: 'space-between',
		alignContent: 'center',
		marginTop: '20px',
		'@media(max-width:500px)': {
			width: '100%',
			height: '62px',
		},
	},
	title: {
		color: '#E55200',
		fontSize: '22px',
		paddingTop: '12px',
	},
	continue: {
		backgroundColor: '#E55200',
		color: 'white',
		textTransform: 'none',
		borderRadius: '10px',
		textShadow: '0px 2px 0px #00000040 !important',
		border: 'none !important',
		marginTop: '14px !important',
		fontWeight: 'bold !important',
		fontSize: '16px !important',
		padding: '5px 18px',
		'&:hover': {
			backgroundColor: '#E55200',
		},
		'&:disabled': {
			color: 'white',
		},
	},
	add: {
		backgroundColor: 'white',
		color: '#E55200',
		textTransform: 'none',
		borderRadius: '10px',
		border: '1px solid #E55200 !important',
		marginTop: '14px !important',
		fontWeight: 'bold !important',
		fontSize: '16px !important',
		padding: '5px 18px',
		'&:hover': {
			backgroundColor: 'white',
		},
		'@media(max-width:500px)': {
			padding: '5px ',
			height: '41px',
			marginTop: '15px',
		},
	},
	btnDel: {
		marginTop: '8px',
	},
}));

const ModalAddSignature = props => {
	const [firmantes, setFirmantes] = useState([]);
	const [tempFirmantes, setTempFirmantes] = useState([]); // Estado temporal para firmantes
	const dispatch = useDispatch();
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const [errorValidationCorreo, setErrorValidationCorreo] = useState('');
	const [textValidationCorreo, setTextValidationCorreo] = useState('');
	const [btnDisabledCorreo, setBtnDisabledCorreo] = useState(true);
	const [errorValidationNombre, setErrorValidationNombre] = useState('');
	const [textValidationNombre, setTextValidationNombre] = useState('');
	const [btnDisabledNombre, setBtnDisabledNombre] = useState(true);
	const [errorValidationApellido, setErrorValidationApellido] = useState('');
	const [textValidationApellido, setTextValidationApellido] = useState('');
	const [btnDisabledApellido, setBtnDisabledApellido] = useState(true);
	const [btnDisabled, setBtnDisabled] = useState(true);
	const [disabledFieldIndex, setDisabledFieldIndex] = useState(-1);
	const [arregloVacio, setArregloVacio] = useState([]);
	const [errores, setErrores] = useState([]);
	const [errorExist, setErrorExist] = useState(false);
	const [addNewSigner, setAddNewSigner] = useState(false);
	const [camposIncompletos, setCamposIncompletos] = useState(false);
	const { setModalflag } = props;
	const dragItem = useRef();
	const dragOverItem = useRef();
	const signers = useSelector(
		({ CircuitoFirmasReducer }) => CircuitoFirmasReducer.firmantes
	);
	const initialNewSigner = {
		Id: v1(),
		Posicion: signers?.length + 1,
		Nombres: '',
		Apellidos: '',
		Correo: '',
		mensajePriv: '',
		firmanteLibre: true,
		Manuscrita: true,
		esAprobador: 0,
		esObservador: 0, // Nuevo campo
		firmaDocumentos: [],
	};
	const [firmanteNuevo, setFirmanteNuevo] = useState(initialNewSigner);

	useEffect(() => {
		setFirmantes(signers);
		setTempFirmantes(signers); // Inicializar firmantes temporales
	}, []);

	useEffect(() => {
		if (btnDisabledCorreo || btnDisabledNombre || btnDisabledApellido) {
			setBtnDisabled(true);
		} else {
			setBtnDisabled(false);
		}
	}, [btnDisabledCorreo, btnDisabledNombre, btnDisabledApellido]);

	useEffect(() => {
		if (firmanteNuevo.Nombres.length < 1) {
			setBtnDisabledNombre(true);
			setErrorValidationNombre(false);
			setTextValidationNombre('');
		}
		if (
			!validateName(firmanteNuevo.Nombres) &&
			firmanteNuevo.Nombres.length >= 1
		) {
			setBtnDisabledNombre(true);
			setErrorValidationNombre(true);
			setTextValidationNombre(
				'Minimo 3 caracteres, no se admiten caracteres especiales'
			);
		}
		if (validateName(firmanteNuevo.Nombres)) {
			setBtnDisabledNombre(false);
			setErrorValidationNombre(false);
			setTextValidationNombre('');
		}
	}, [firmanteNuevo.Nombres]);

	useEffect(() => {
		if (firmanteNuevo.Apellidos.length < 1) {
			setBtnDisabledApellido(true);
			setErrorValidationApellido(false);
			setTextValidationApellido('');
		}
		if (
			!validateName(firmanteNuevo.Apellidos) &&
			firmanteNuevo.Apellidos.length >= 1
		) {
			setBtnDisabledApellido(true);
			setErrorValidationApellido(true);
			setTextValidationApellido(
				'Minimo 3 caracteres, no se admiten caracteres especiales'
			);
		}
		if (validateName(firmanteNuevo.Apellidos)) {
			setBtnDisabledApellido(false);
			setErrorValidationApellido(false);
			setTextValidationApellido('');
		}
	}, [firmanteNuevo.Apellidos]);

	useEffect(() => {
		if (firmanteNuevo.Correo.length < 1) {
			setBtnDisabledCorreo(true);
			setErrorValidationCorreo(false);
			setTextValidationCorreo('');
		}
		if (validateEmail(firmanteNuevo.Correo)) {
			if (
				tempFirmantes?.some(
					firmante => firmante.Correo === firmanteNuevo.Correo
				)
			) {
				setBtnDisabledCorreo(true);
				setErrorValidationCorreo(true);
				setTextValidationCorreo(
					'Este email ya pertenece al circuito de firmas'
				);
			} else {
				setBtnDisabledCorreo(false);
				setErrorValidationCorreo(false);
				setTextValidationCorreo('');
			}
		} else if (firmanteNuevo.Correo.length < 1) {
			setErrorValidationCorreo(false);
			setTextValidationCorreo('');
		} else {
			// El correo electrónico es inválido
			setBtnDisabledCorreo(true);
			setErrorValidationCorreo(true);
			setTextValidationCorreo('Escribe un email válido');
		}
	}, [firmanteNuevo.Correo]);

	useEffect(() => {
		if (!addNewSigner) {
			setBtnDisabled(false);
		} else {
			setBtnDisabled(true);
		}
	}, [addNewSigner]);

	useEffect(() => {
		if (tempFirmantes.length === 0 && !addNewSigner) {
			setAddNewSigner(true);
			setFirmanteNuevo({
				...firmanteNuevo,
				Posicion: signers?.length + 1,
			});
		}
	}, [tempFirmantes]);

	useEffect(() => {
		if (
			firmanteNuevo.Nombres.trim() !== '' ||
			firmanteNuevo.Apellidos.trim() !== '' ||
			firmanteNuevo.Correo.trim() !== ''
		) {
			setCamposIncompletos(true);
		} else setCamposIncompletos(false);
	}, [firmanteNuevo, tempFirmantes]);

	useEffect(() => {
		const fieldWithErrorIndex = errores.findIndex(obj => {
			return Object.values(obj).some(value => value !== '');
		});

		setDisabledFieldIndex(fieldWithErrorIndex);
	}, [errores]);

	/**
	 * Combina las clases CSS proporcionadas en una sola cadena de clases.
	 * Útil para aplicar múltiples clases a un elemento de manera dinámica.
	 * @returns {string} Una cadena de clases CSS combinadas.
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	const combinedClasses = clsx(classes.select, classes.field);
	/**
	 * Maneja el evento de presionar una tecla en un campo de entrada.
	 * Si se presiona la tecla de espacio y el valor del campo termina con un espacio, previene el comportamiento predeterminado del evento.
	 * @param {Event} e - El evento de tecla que desencadena el manejo.
	 * @returns {void} No retorna nada explícitamente, pero puede prevenir el comportamiento predeterminado del evento.
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	const handleKeyPress = e => {
		if (e.key === ' ' && e.target.value.endsWith(' ')) {
			e.preventDefault();
		}
	};

	/**
	 * Cierra el modal actual y previene el comportamiento predeterminado del evento.
	 * @param {Event} event - El evento de cierre del modal.
	 * @returns {void} No retorna nada explícitamente, pero cierra el modal y previene el comportamiento predeterminado del evento.
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */

	const closeModal = event => {
		event.preventDefault();
		dispatch(modalAddFirmantes(false));
	};
	/**
	 * Valida y procesa la solicitud para agregar un nuevo firmante.
	 * Si los campos requeridos están completos y la dirección de correo electrónico es válida,
	 * agrega el nuevo firmante al circuito de firmas. Si no, muestra mensajes de error adecuados.
	 * @returns {void} No retorna nada explícitamente, pero puede agregar un nuevo firmante al circuito de firmas o mostrar mensajes de error.
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	const validateNewSigner = () => {
		if (!btnDisabled) {
			if (validateEmail(firmanteNuevo.Correo)) {
				if (
					tempFirmantes?.some(
						firmante => firmante.Correo === firmanteNuevo.Correo
					)
				) {
					enqueueSnackbar(
						`Ya existe alguien con este correo en el circuito de firmas `,
						{
							style: { whiteSpace: 'pre-line' },
							variant: 'error',
							anchorOrigin: {
								vertical: 'top',
								horizontal: 'right',
							},
						}
					);
				} else {
					setTempFirmantes([...tempFirmantes, firmanteNuevo]);
					setAddNewSigner(false);
					setFirmanteNuevo({
						...initialNewSigner,
						Posicion: tempFirmantes?.length + 2,
					});
				}
			}
		} else {
			if (
				firmanteNuevo.Nombres.length < 1 &&
				firmanteNuevo.Apellidos.length < 1 &&
				firmanteNuevo.Correo < 1
			) {
				enqueueSnackbar(
					`Debes llenar los campos para poder solicitar otro firmante`,
					{
						style: { whiteSpace: 'pre-line' },
						variant: 'error',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
					}
				);
			} else if (firmanteNuevo.Nombres.length < 1) {
				enqueueSnackbar(`Debes llenar el campo nombre`, {
					style: { whiteSpace: 'pre-line' },
					variant: 'error',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
				});
			} else if (firmanteNuevo.Apellidos.length < 1) {
				enqueueSnackbar(`Debes llenar el campo apellidos`, {
					style: { whiteSpace: 'pre-line' },
					variant: 'error',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
				});
			}
		}
	};
	/**
	 * Agrega un nuevo firmante si los campos de nombres y apellidos tienen al menos 3 caracteres
	 * y la dirección de correo electrónico es válida.
	 * Invoca la función de validación `validateNewSigner` para procesar la solicitud de agregar un nuevo firmante.
	 * @returns {void} No retorna nada explícitamente, pero puede invocar la función `validateNewSigner`.
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	const addSigner = () => {
		if (
			firmanteNuevo.Nombres.length >= 3 &&
			firmanteNuevo.Apellidos.length >= 3 &&
			validateEmail(firmanteNuevo.Correo)
		) {
			validateNewSigner();
		}
	};
	/**
	 * Restablece el estado de los campos y controles relacionados.
	 * Si no hay firmantes agregados, restablece el formulario para agregar un nuevo firmante.
	 * También restablece el índice del campo deshabilitado, y los estados de validación de correo electrónico,
	 * apellido y nombre a su estado inicial.
	 * @param {Event} event - El evento que desencadena el restablecimiento del formulario, vaciar los campos por si se desea agregar nuevos firmantes
	 * @returns {void} No retorna nada explícitamente, pero restablece varios estados relacionados.
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	const reset = event => {
		if (tempFirmantes.length === 0) {
			setFirmanteNuevo({
				...initialNewSigner,
				Posicion: tempFirmantes?.length + 1,
			});
		} else setAddNewSigner(false);
		setDisabledFieldIndex(-1);

		setErrorValidationCorreo(false);

		setErrorValidationApellido(false);

		setErrorValidationNombre(false);
	};

	/**
	 * Actualiza los detalles de un firmante en la lista de firmantes.
	 * Actualiza el campo especificado del firmante en la posición de índice proporcionada con el nuevo valor.
	 * También realiza validaciones de campos y gestiona errores.
	 * @param {number} index - El índice del firmante en la lista de firmantes que se actualizará.
	 * @param {string} campo - El campo del firmante que se actualizará (por ejemplo, 'esAprobador', 'Manuscrita', etc.).
	 * @param {string} valor - El nuevo valor que se asignará al campo especificado del firmante.
	 * @param {string} [prevValue=''] - El valor anterior del campo (opcional, utilizado para ciertas validaciones).
	 * @returns {void} No retorna nada explícitamente, pero actualiza la lista de firmantes y gestiona los errores.
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */

	const updateSigner = async (index, campo, valor, prevValue = '') => {
		const nuevosFirmantes = tempFirmantes.map((firmante, i) => {
			if (i === index) {
				if (campo === 'esAprobador') {
					return {
						...firmante,
						esAprobador: valor ? 1 : 0,
						esObservador: 0, // Desactivar observador si es aprobador
					};
				} else if (campo === 'esObservador') {
					if (valor && firmante.firmaDocumentos.length > 0) {
						enqueueSnackbar(
							`Por favor, elimine los campos de firma de este firmante antes de cambiar a observador`,
							{
								variant: 'error',
								anchorOrigin: {
									vertical: 'top',
									horizontal: 'right',
								},
							}
						);
						return firmante; // No cambiar a observador
					} else {
						return {
							...firmante,
							esAprobador: 0, // Desactivar aprobador si es observador
							esObservador: valor ? 1 : 0,
						};
					}
				} else if (campo === 'Manuscrita') {
					return {
						...firmante,
						[campo]: valor,
						firmanteLibre: valor,
					};
				} else {
					return {
						...firmante,
						[campo]: valor.replace(/\s+/g, ' ').trimStart(),
					};
				}
			} else {
				return firmante;
			}
		});
		setTempFirmantes(nuevosFirmantes);

		const nuevosErrores = [...errores];
		for (let i = 0; i <= index; i++) {
			if (!nuevosErrores[i]) {
				nuevosErrores[i] = {};
			}
		}
		if (
			campo !== 'Manuscrita' &&
			campo !== 'firmanteLibre' &&
			campo !== 'esAprobador' &&
			campo !== 'esObservador'
		) {
			const error = validateField(
				campo,
				valor.replace(/\s+/g, ' ').trimStart(),
				index
			);
			nuevosErrores[index] = {
				...nuevosErrores[index],
				[campo]: error,
			};

			if (campo === 'Correo' && error === '') {
				const firmantesConMismoCorreo = nuevosFirmantes.filter(
					firmante => firmante.Correo === prevValue
				);
				if (firmantesConMismoCorreo.length === 1) {
					const firmanteConPrevValue = tempFirmantes.find(
						firmante => firmante.Correo === prevValue
					);
					if (firmanteConPrevValue) {
						const indexFirmanteConPrevValue = nuevosFirmantes.findIndex(
							firmante => firmante.Correo === prevValue
						);
						nuevosErrores[indexFirmanteConPrevValue] = {
							...nuevosErrores[indexFirmanteConPrevValue],
							[campo]: '',
						};
					}
				}
			}
			setErrores(nuevosErrores);

			const isError = nuevosErrores.some(error => {
				return (
					(error.Nombres !== undefined && error.Nombres.trim() !== '') ||
					(error.Apellidos !== undefined && error.Apellidos.trim() !== '') ||
					(error.Correo !== undefined && error.Correo.trim() !== '')
				);
			});
			if (isError) {
				setErrorExist(true);
			} else {
				setErrorExist(false);
			}

			if (
				firmanteNuevo.Nombres.length >= 3 &&
				firmanteNuevo.Apellidos.length >= 3 &&
				validateEmail(firmanteNuevo.Correo)
			) {
				setTempFirmantes([...nuevosFirmantes, firmanteNuevo]);
				setAddNewSigner(false);
				setFirmanteNuevo(initialNewSigner);
			}
		}
	};
	/**
	 * Valida el valor de un campo específico de un firmante.
	 * Según el campo proporcionado, invoca la función de validación correspondiente y devuelve el resultado.
	 * @param {string} campo - El nombre del campo que se va a validar (por ejemplo, 'Nombres', 'Apellidos', 'Correo').
	 * @param {string} valor - El valor del campo que se va a validar.
	 * @param {number} index - El índice del firmante en la lista de firmantes (utilizado para algunas validaciones específicas).
	 * @returns {string} El mensaje de error generado por la validación del campo, o una cadena vacía si no hay errores.
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	const validateField = (campo, valor, index) => {
		switch (campo) {
			case 'Nombres':
				return validateNames(valor, true);
			case 'Apellidos':
				return validateNames(valor, false);
			case 'Correo':
				return mailValidator(valor, index);
			default:
				return '';
		}
	};

	const mostrarMensajeError = () => {
		enqueueSnackbar(
			'Debe haber al menos un firmante que no sea observador en el circuito.',
			{
				variant: 'error',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'right',
				},
			}
		);
	};
	/**
	 * Esta funcion valida si el nombre ingresado cumple con los requisitos especificados, obligatorio, minimo 3 caracteres,
	 * sin caracteres especiales y sin numeros.
	 * @param {String} nombre El nombre a validar para que cumpla con los requisitos.
	 * @param {Boolean} isName isName es un booleano que valida si el mensaje a mostar debe llevar (nombre) si es true o (apellido) si es false
	 * @returns {String} Retorna un string en donde especifica la condicion que no se cumple.
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	const validateNames = (nombre, isName) => {
		if (!nombre) {
			return isName ? 'El nombre es obligatorio' : 'El apellido es obligatorio';
		}
		if (nombre.length < 3) {
			return isName
				? 'El nombre debe tener al menos 3 caracteres'
				: 'El apellido debe tener al menos 3 caracteres';
		}
		if (/^[a-zA-ZáéíóúÁÉÍÓÚñÑ ]+$/.test(nombre)) {
			return '';
		}
		return isName
			? 'El nombre no puede contener números ni caracteres especiales'
			: 'El apellido no puede contener números ni caracteres especiales';
	};
	/**
	 * Esta funcion valida si el correo ingresado cumple con los requisitos especificados, obligatorio,
	 * no puede estar repetido, debe cumplir con el formato de correo electronico (@)
	 * @param {String} correo El correo a validar para que cumpla con los requisitos
	 * @param {Number} index el index del firmante actual para la validacion del correo
	 * @returns {String} Retorna un string en donde especifica la condicion que no se cumple.
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	const mailValidator = (correo, index) => {
		const re = new RegExp(
			"^[\\w!#$%&'*+/=?`{|}~^-]+(?:\\.[\\w!#$%&'*+/=?`{|}~^-]+)*@(?:[a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,6}$"
		);
		const parts = correo.split('@');
		const [user, domain] = parts;
		const firmantesSinIndex = tempFirmantes.filter((_fi, i) => i !== index);
		if (firmantesSinIndex.some(firmante => firmante.Correo === correo)) {
			return 'El correo ya existe';
		}

		if (!correo) {
			return 'El correo electronico es obligatorio';
		}
		if (parts.length !== 2) {
			return 'El correo electronico debe contener un @';
		}

		if (user.length > 64 || domain.length > 255) {
			return 'El correo electronico no es valido';
		}
		if (!re.test(correo)) {
			return 'El correo no tiene un formato valido';
		}
		return '';
	};

	/**
	 * Esta funcion crea una copia de firmantes y reorganiza el array cuando se hayan arrastrado los campos.
	 * Reubica la lista de firmantes.
	 * @returns {Array} Retorna una nueva lista con las nuevas posiciones de los firmantes
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	const handleSort = () => {
		let _firmantes = [...tempFirmantes];
		const draggedItemContent = _firmantes.splice(dragItem.current, 1)[0];
		_firmantes.splice(dragOverItem.current, 0, draggedItemContent);
		dragItem.current = null;
		dragOverItem.current = null;
		const posicionesFirmantes = _firmantes.map((firmante, index) => {
			if (firmante.Posicion !== index + 1) {
				return { ...firmante, Posicion: index + 1 };
			}
			return firmante;
		});
		setTempFirmantes(posicionesFirmantes);
	};
	/**
	 * Esta función elimina un firmante y sus campos de firma asociados, actualizando los estados pertinentes.
	 * Primero, filtra los errores para eliminar el error asociado con el firmante que se está eliminando.
	 * Luego, recoge los IDs de los campos de firma asociados con el firmante a eliminar.
	 * Si existen campos de firma asociados, los elimina del DOM.
	 * Posteriormente, actualiza los errores y los firmantes sin el firmante eliminado.
	 * Finalmente, verifica si aún existen errores después de la eliminación y realiza las validaciones necesarias.
	 *
	 * @param {number} index - El índice del firmante a eliminar.
	 * @param {Event} event - El evento de clic que desencadenó la eliminación del firmante.
	 * @returns {void} No retorna nada pero actualiza los estados de errores, firmantes, addedFields y errorExist.
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	const deleteSigner = (index, event) => {
		event.preventDefault();

		const nuevosErrores = errores.filter((error, i) => {
			return i !== index;
		});

		const firmaDocumentosIds = [];
		for (let i = 0; i < tempFirmantes[index].firmaDocumentos.length; i++) {
			if (tempFirmantes[index].firmaDocumentos[i]) {
				firmaDocumentosIds.push(
					tempFirmantes[index].firmaDocumentos[i].PosicionFirma.id
				);
			}
		}

		if (firmaDocumentosIds.length > 0) {
			for (var i = 0; i < firmaDocumentosIds.length; i++) {
				var id = firmaDocumentosIds[i];
				if (document.getElementById('container-' + id)) {
					//document.getElementById('container-' + id).remove();
				}
			}
		}

		for (let i = nuevosErrores.length; i < tempFirmantes.length; i++) {
			nuevosErrores[i] = {};
		}

		setErrores(nuevosErrores);
		const nuevosFirmantes = [...tempFirmantes];
		let idElim = nuevosFirmantes[index].Posicion;
		let totData = props.addedFields.filter(elm => elm.id !== idElim);
		props.setAddedFields(totData);
		nuevosFirmantes.splice(index, 1);
		setTempFirmantes(nuevosFirmantes);

		const isError = nuevosErrores.some(error => {
			return (
				(error.Nombres !== undefined && error.Nombres.trim() !== '') ||
				(error.Apellidos !== undefined && error.Apellidos.trim() !== '') ||
				(error.Correo !== undefined && error.Correo.trim() !== '')
			);
		});
		setErrorExist(isError);

		if (
			textValidationCorreo === 'Este email ya pertenece al circuito de firmas'
		) {
			validateEmailCFDelete(nuevosFirmantes, tempFirmantes, index);
		}
	};
	/**
	 * Esta función valida si un nuevo firmante puede ser agregado a la lista de firmantes después de eliminar otro firmante.
	 * Si el correo electrónico del nuevo firmante es igual al del firmante eliminado, se realiza la validación.
	 * Si el nuevo firmante cumple con los criterios de nombres, apellidos y formato de correo electrónico,
	 * se agrega a la lista de firmantes y se restablecen ciertos estados.
	 *
	 * @param {object[]} nuevosFirmantes - La lista actualizada de firmantes después de eliminar el firmante.
	 * @param {object[]} firmantes - La lista original de firmantes antes de la eliminación.
	 * @param {number} index - El índice del firmante eliminado en la lista original de firmantes.
	 * @returns {void} No retorna nada pero actualiza los estados pertinentes según el resultado de la validación.
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	const validateEmailCFDelete = (nuevosFirmantes, firmantes, index) => {
		const firm = tempFirmantes.find((firmant, i) => i === index);

		if (firm.Correo === firmanteNuevo.Correo) {
			setTextValidationCorreo('');
			setErrorValidationCorreo(false);
			setBtnDisabledCorreo(false);
			if (
				firmanteNuevo.Nombres.length >= 3 &&
				firmanteNuevo.Apellidos.length >= 3 &&
				validateEmail(firmanteNuevo.Correo)
			) {
				setTempFirmantes([...nuevosFirmantes, firmanteNuevo]);
				setAddNewSigner(false);
				setFirmanteNuevo(initialNewSigner);
			}
		}
	};
	/**
	 * Esta función activa el modal de guardado y realiza varias validaciones antes de guardar los datos de los firmantes.
	 * Primero, recopila los correos electrónicos de todos los firmantes y verifica si hay correos electrónicos duplicados.
	 * Si se encuentran correos electrónicos duplicados, muestra una notificación de error.
	 * Luego, verifica si hay al menos un firmante. Si no hay firmantes, muestra una notificación de error.
	 * Si hay firmantes, asigna posiciones a los firmantes y realiza ajustes necesarios en los datos antes de guardarlos.
	 * Finalmente, actualiza el estado de los firmantes y cierra el modal de agregar firmantes.
	 *
	 * @returns {void} No retorna nada pero activa el modal de guardado y actualiza el estado de los firmantes.
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	const btnGuardar = () => {
		setModalflag(true);
		const correos = tempFirmantes.map(firmante => firmante.Correo);
		const conteoCorreos = correos.reduce((conteo, correo) => {
			conteo[correo] = (conteo[correo] || 0) + 1;
			return conteo;
		}, {});

		const correosDuplicados = [];

		for (const correo in conteoCorreos) {
			if (conteoCorreos[correo] > 1) {
				correosDuplicados.push(correo);
			}
		}
		if (correosDuplicados.length > 0) {
			enqueueSnackbar(
				`Los siguientes correos están repetidos: \n${correosDuplicados.join(
					', '
				)} `,
				{
					variant: 'error',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
				}
			);
		} else {
			if (tempFirmantes.length > 0) {
				const hayFirmanteNoObservador = tempFirmantes.some(
					firmante => firmante.esObservador === 0
				);
				if (!hayFirmanteNoObservador) {
					mostrarMensajeError();
					return;
				}
				const posicionesFirmantes = tempFirmantes.map((firmante, index) => {
					let firmanteLibre = true;
					if (firmante.Manuscrita) {
						if (
							firmante.firmaDocumentos &&
							firmante.firmaDocumentos.length > 0
						) {
							firmanteLibre = false;
						}
					} else {
						firmanteLibre = false;
						firmante.firmaDocumentos = [];
					}
					return {
						...firmante,
						Posicion: index + 1,
						firmanteLibre,
					};
				});

				props.listColorsChoose.forEach(colors => {
					const firmanteCorrespondiente = tempFirmantes.find(
						firmante =>
							firmante.Id === colors.Id && firmante.Correo !== colors.Mail
					);

					if (firmanteCorrespondiente) {
						colors.Mail = firmanteCorrespondiente.Correo;
					}
				});

				setFirmantes(posicionesFirmantes); // Actualizar firmantes finales
				setTempFirmantes(posicionesFirmantes); // Actualizar firmantes temporales
				dispatch(firm_positions(posicionesFirmantes));
				dispatch(modalAddFirmantes(false));
			} else {
				enqueueSnackbar(`Debes tener al menos un firmante `, {
					style: { whiteSpace: 'pre-line' },
					variant: 'error',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
				});
			}
		}
	};
	/**
	 * Esta función agrega un nuevo firmante a la lista de firmantes si la bandera 'addNewSigner' es falsa.
	 * Primero, verifica si la bandera 'addNewSigner' es falsa.
	 * Si es falsa, establece los detalles del nuevo firmante, incluida su posición, y activa la bandera 'addNewSigner'.
	 *
	 * @returns {void} No retorna nada pero actualiza el estado del nuevo firmante y la bandera 'addNewSigner'.
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	const addnewSignerToList = () => {
		if (!addNewSigner) {
			setFirmanteNuevo({
				...initialNewSigner,
				Posicion: tempFirmantes?.length + 1,
			});
			setAddNewSigner(true);
		}
	};

	const disabledSelect = () => {
		if (
			firmanteNuevo.Nombres.trim().length === 0 ||
			firmanteNuevo.Apellidos.trim().length === 0 ||
			firmanteNuevo.Correo.trim().length === 0
		) {
			return true;
		} else {
			return false;
		}
	};

	/**
	 * Esta función determina si el selector de firmante nuevo debe estar desactivado o no, basado en la validez de los datos del nuevo firmante.
	 * Verifica si los campos de nombres, apellidos y correo electrónico del nuevo firmante están vacíos.
	 * Si alguno de los campos está vacío, devuelve true (indicando que el selector debe estar desactivado).
	 * De lo contrario, devuelve false (indicando que el selector puede estar activado).
	 *
	 * @returns {boolean} True si alguno de los campos de nombres, apellidos o correo electrónico está vacío; de lo contrario, false.
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	const disabledFields = index =>
		(disabledFieldIndex !== -1 && index !== disabledFieldIndex) ||
		errorValidationApellido ||
		errorValidationNombre ||
		errorValidationCorreo;

	return (
		<Modal open={true} disableBackdropClick>
			<div className={classes.modal}>
				<img
					src={close}
					className={classes.close}
					onClick={event => closeModal(event)}
					alt='close'
				/>
				<h2 className={classes.title}>Agregar firmantes</h2>
				{tempFirmantes?.map((firmante, index) => (
					<>
						<div
							className={classes.containerFirmantes}
							draggable={errorExist ? false : true}
							key={firmante}
							onDragStart={errorExist ? null : e => (dragItem.current = index)}
							onDragEnter={
								errorExist ? null : e => (dragOverItem.current = index)
							}
							onDragEnd={errorExist ? null : handleSort}
							onDragOver={errorExist ? null : e => e.preventDefault()}
						>
							<div className={classes.square}>
								<span>{index + 1}</span>
							</div>
							<TextField
								InputProps={{ disableUnderline: true }}
								inputProps={{
									style: {
										fontSize: '14px',
										fontFamily: 'Mulish',
										backgroundColor: 'white',
										border: '1px solid #706F6F',
										borderRadius: '10px',
										boxShadow: '0 1px 3px 0 rgba(0,0,0,0.46)',
										maxHeight: '6px',
									},
								}}
								InputLabelProps={{ style: { fontSize: '14px' } }}
								className={classes.textField}
								label='Nombres'
								name='Nombres'
								value={firmante.Nombres}
								onKeyPress={handleKeyPress}
								fullWidth
								size='small'
								variant='filled'
								onChange={event =>
									updateSigner(index, 'Nombres', event.target.value)
								}
								disabled={disabledFields(index)}
								error={
									errores
										? errores[index]
											? errores[index].Nombres
												? errores[index].Nombres !== ''
													? true
													: false
												: false
											: false
										: false
								}
								helperText={
									errores
										? errores[index]
											? errores[index].Nombres
												? errores[index].Nombres !== ''
													? errores[index].Nombres
													: ''
												: ''
											: ''
										: ''
								}
							/>
							<TextField
								InputProps={{ disableUnderline: true }}
								inputProps={{
									style: {
										fontSize: '14px',
										fontFamily: 'Mulish',
										backgroundColor: 'white',
										border: '1px solid #706F6F',
										borderRadius: '10px',
										boxShadow: '0 1px 3px 0 rgba(0,0,0,0.46)',
										maxHeight: '6px',
									},
								}}
								InputLabelProps={{ style: { fontSize: '14px' } }}
								className={classes.textField}
								label='Apellidos'
								name='Apellidos'
								fullWidth
								variant='filled'
								size='small'
								value={firmante.Apellidos}
								onKeyPress={handleKeyPress}
								onChange={event =>
									updateSigner(index, 'Apellidos', event.target.value)
								}
								disabled={disabledFields(index)}
								error={
									errores
										? errores[index]
											? errores[index].Apellidos
												? errores[index].Apellidos !== ''
													? true
													: false
												: false
											: false
										: false
								}
								helperText={
									errores
										? errores[index]
											? errores[index].Apellidos
												? errores[index].Apellidos !== ''
													? errores[index].Apellidos
													: ''
												: ''
											: ''
										: ''
								}
							/>
							<TextField
								InputProps={{ disableUnderline: true }}
								inputProps={{
									style: {
										fontSize: '14px',
										fontFamily: 'Mulish',
										backgroundColor: 'white',
										border: '1px solid #706F6F',
										borderRadius: '10px',
										boxShadow: '0 1px 3px 0 rgba(0,0,0,0.46)',
										maxHeight: '6px',
									},
								}}
								InputLabelProps={{
									style: { fontSize: '14px', fontFamily: 'Mulish' },
								}}
								className={classes.textField}
								label='Correo'
								name='Correo'
								fullWidth
								size='small'
								variant='filled'
								value={firmante.Correo}
								onKeyPress={handleKeyPress}
								onChange={event => {
									updateSigner(
										index,
										'Correo',
										event.target.value,
										firmante.Correo
									);
								}}
								disabled={disabledFields(index)}
								error={
									errores
										? errores[index]
											? errores[index].Correo
												? errores[index].Correo !== ''
													? true
													: false
												: false
											: false
										: false
								}
								helperText={
									errores
										? errores[index]
											? errores[index].Correo
												? errores[index].Correo !== ''
													? errores[index].Correo
													: ''
												: ''
											: ''
										: ''
								}
							/>
							<Select
								value={firmante.Manuscrita}
								onChange={event => {
									updateSigner(index, 'Manuscrita', event.target.value);
								}}
								disabled={disabledFields(index)}
								displayEmpty
								className={classes.field}
								name='Manuscrita'
								size='small'
								SelectDisplayProps={{
									style: {
										fontSize: '14px',
										paddingLeft: '5px',
										height: '6px',
									},
								}}
								inputProps={{ 'aria-label': 'Without label' }}
							>
								<MenuItem value={false}>
									<em>Sin firma manuscrita</em>
								</MenuItem>
								<MenuItem value={true}>Con firma manuscrita</MenuItem>
							</Select>

							<Select
								value={
									firmante.esAprobador
										? 'Aprobador'
										: firmante.esObservador
										? 'Observador'
										: 'Rol'
								}
								onChange={event => {
									const valor = event.target.value;
									if (valor === 'Aprobador') {
										updateSigner(index, 'esAprobador', 1);
									} else if (valor === 'Observador') {
										updateSigner(index, 'esObservador', 1);
									} else {
										updateSigner(index, 'esAprobador', 0);
										updateSigner(index, 'esObservador', 0);
									}
								}}
								disabled={disabledFields(index)}
								displayEmpty
								className={classes.field}
								name='Rol'
								size='small'
								SelectDisplayProps={{
									style: {
										fontSize: '14px',
										paddingLeft: '5px',
										height: '6px',
									},
								}}
								inputProps={{ 'aria-label': 'Without label' }}
							>
								<MenuItem value='Rol'>
									<Tooltip title='Ninguno' placement='top'>
										<em>Sin Rol</em>
									</Tooltip>
								</MenuItem>
								<MenuItem value='Aprobador'>
									<Tooltip title='Aprobador' placement='top'>
										<CheckCircleIcon style={{ marginRight: 8 }} />
									</Tooltip>
								</MenuItem>
								<MenuItem value='Observador'>
									{' '}
									<Tooltip title='Observador' placement='top'>
										<VisibilityIcon style={{ marginRight: 8 }} />
									</Tooltip>
								</MenuItem>
							</Select>

							<button
								onClick={event => {
									deleteSigner(index, event);
								}}
								className={classes.btnDel}
							>
								<img src={del} alt='del' />
							</button>
						</div>
					</>
				))}
				{addNewSigner && (
					<div className={classes.containerFirmantes} draggable>
						<div className={classes.square}>
							<span>{tempFirmantes?.length + 1}</span>
						</div>
						<TextField
							onBlur={addSigner}
							InputProps={{ disableUnderline: true }}
							inputProps={{
								style: {
									fontSize: '14px',
									fontFamily: 'Mulish',
									backgroundColor: 'white',
									border: '1px solid #706F6F',
									borderRadius: '10px',
									boxShadow: '0 1px 3px 0 rgba(0,0,0,0.46)',
									maxHeight: '6px',
								},
							}}
							InputLabelProps={{ style: { fontSize: '14px' } }}
							className={classes.textField}
							error={errorValidationNombre}
							helperText={textValidationNombre}
							label='Nombres'
							name='Nombres'
							value={firmanteNuevo.Nombres}
							onKeyPress={handleKeyPress}
							fullWidth
							size='small'
							variant='filled'
							onChange={event => {
								setFirmanteNuevo({
									...firmanteNuevo,
									Nombres: event.target.value.replace(/\s+/g, ' ').trimStart(),
								});
							}}
						/>
						<TextField
							onBlur={addSigner}
							InputProps={{ disableUnderline: true }}
							inputProps={{
								style: {
									fontSize: '14px',
									fontFamily: 'Mulish',
									backgroundColor: 'white',
									border: '1px solid #706F6F',
									borderRadius: '10px',
									boxShadow: '0 1px 3px 0 rgba(0,0,0,0.46)',
									maxHeight: '6px',
								},
							}}
							InputLabelProps={{ style: { fontSize: '14px' } }}
							className={classes.textField}
							error={errorValidationApellido}
							helperText={textValidationApellido}
							label='Apellidos'
							name='Apellidos'
							value={firmanteNuevo.Apellidos}
							onKeyPress={handleKeyPress}
							fullWidth
							variant='filled'
							size='small'
							onChange={event =>
								setFirmanteNuevo({
									...firmanteNuevo,
									Apellidos: event.target.value
										.replace(/\s+/g, ' ')
										.trimStart(),
								})
							}
						/>
						<TextField
							onBlur={addSigner}
							InputProps={{ disableUnderline: true }}
							inputProps={{
								style: {
									fontSize: '14px',
									fontFamily: 'Mulish',
									backgroundColor: 'white',
									border: '1px solid #706F6F',
									borderRadius: '10px',
									boxShadow: '0 1px 3px 0 rgba(0,0,0,0.46)',
									maxHeight: '6px',
								},
							}}
							InputLabelProps={{ style: { fontSize: '14px' } }}
							className={classes.textField}
							error={errorValidationCorreo}
							helperText={textValidationCorreo}
							label='Correo'
							name='Correo'
							value={firmanteNuevo.Correo}
							onKeyPress={handleKeyPress}
							fullWidth
							size='small'
							variant='filled'
							onChange={event => {
								setFirmanteNuevo({
									...firmanteNuevo,
									Correo: event.target.value.replace(/\s+/g, ' ').trimStart(),
								});
							}}
						/>
						<Select
							value={firmanteNuevo.Manuscrita}
							onChange={event =>
								setFirmanteNuevo({
									...firmanteNuevo,
									firmanteLibre: event.target.value,
									Manuscrita: event.target.value,
								})
							}
							displayEmpty
							disabled={disabledSelect()}
							className={combinedClasses}
							size='small'
							SelectDisplayProps={{
								style: { fontSize: '14px', paddingLeft: '5px' },
							}}
							inputProps={{ 'aria-label': 'Without label' }}
						>
							<MenuItem value={false}>
								<em>Sin firma manuscrita</em>
							</MenuItem>
							<MenuItem value={true}>Con firma manuscrita</MenuItem>
						</Select>

						<Select
							value={
								firmanteNuevo.esAprobador
									? 'Aprobador'
									: firmanteNuevo.esObservador
									? 'Observador'
									: 'Rol'
							}
							onChange={event => {
								const valor = event.target.value;
								if (valor === 'Aprobador') {
									setFirmanteNuevo({
										...firmanteNuevo,
										esAprobador: 1,
										esObservador: 0,
									});
								} else if (valor === 'Observador') {
									setFirmanteNuevo({
										...firmanteNuevo,
										esAprobador: 0,
										esObservador: 1,
									});
								} else {
									setFirmanteNuevo({
										...firmanteNuevo,
										esAprobador: 0,
										esObservador: 0,
									});
								}
							}}
							displayEmpty
							className={combinedClasses}
							size='small'
							SelectDisplayProps={{
								style: { fontSize: '14px', paddingLeft: '5px' },
							}}
							inputProps={{ 'aria-label': 'Without label' }}
						>
							<MenuItem value='Rol'>
								<Tooltip title='Ninguno' placement='top'>
									<em>Sin Rol</em>
								</Tooltip>
							</MenuItem>
							<MenuItem value='Aprobador'>
								<Tooltip title='Aprobador' placement='top'>
									<CheckCircleIcon style={{ marginRight: 8 }} />
								</Tooltip>
							</MenuItem>
							<MenuItem value='Observador'>
								{' '}
								<Tooltip title='Observador' placement='top'>
									<VisibilityIcon style={{ marginRight: 8 }} />
								</Tooltip>
							</MenuItem>
						</Select>

						<button className={classes.btnDel} onClick={event => reset(event)}>
							<img src={del} alt='del' />
						</button>
					</div>
				)}

				<Box className={classes.containerBtns}>
					<Button
						onClick={() => addnewSignerToList()}
						disabled={
							(btnDisabled && camposIncompletos) || errorExist || addNewSigner
						}
						className={classes.add}
					>
						<img src={add} alt='add' />
						<span style={{ marginLeft: '10px' }}>
							<b>Agregar firmante</b>
						</span>
					</Button>
					<Button
						disabled={(btnDisabled && camposIncompletos) || errorExist}
						onClick={() => btnGuardar()}
						className={classes.continue}
					>
						Continuar
						<ArrowForwardIosIcon
							style={{ marginLeft: '10px', width: '15px' }}
						/>
					</Button>
				</Box>
			</div>
		</Modal>
	);
};

export default ModalAddSignature;
