import React, { useState } from 'react';
import avion from '../../../../assets/img/sendDocument.png';
import { Button, makeStyles, Modal } from '@material-ui/core';
import { cleanFiles, filesCf, firm_positions, modalSendMessage, resetCF } from '../../../../redux/actions/CircuitoFirmasAction';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { borrarIndexedDB } from '../../../../redux/actions/indexedDB';

const useStyles = makeStyles((theme) => ({
    modal: {
        position: "absolute",
        backgroundColor: "white",
        boxShadow: '0px 3px 0px #00000033 !important',
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        borderRadius: '10px',
        width: "544px",
        height: "421px",
        paddingBottom: "30px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        "&:focus": {
            outline: "none"
        },
        "@media(max-width:700px)": {
            width: "350px",
            height: "450px",
        },
    },
    pSize: {
        fontSize: "16px",
        padding: " 0 55px",
        fontFamily: "Mulish",
        textAlign: "center",
        marginTop: "30px",
    },
    pSizes: {
        fontSize: "16px",
        padding: " 0 55px",
        fontFamily: "Mulish",
        textAlign: "center",
        marginTop: "15px",
    },
    flx: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "32px",
        marginTop: "10px",
        paddingBottom: "10px"
    },
    img: {
        marginTop: "45px",
        marginBottom: "12px",
        "@media(max-width:700px)": {
            marginTop: "0px",
        },
    },
    continue: {
        backgroundColor: "#E55200",
        color: "white",
        textTransform: "none",
        borderRadius: "10px",
        fontWeight: "bold",
        border: "none !important",
        fontWeight: "bold !important",
        fontSize: "16px !important",
        padding: "5px 25px",
        "&:hover": {
            backgroundColor: "#E55200",
        },
        "@media(max-width:700px)": {
            padding: "5px 15px",
        },
    },
    add: {
        backgroundColor: "white",
        color: "#E55200",
        textTransform: "none",
        borderRadius: "10px",
        fontWeight: "bold",
        border: "1px solid #E55200 !important",
        fontWeight: "bold !important",
        fontSize: "16px !important",
        padding: "5px 25px",
        "&:hover": {
            backgroundColor: "white",
        },
        "@media(max-width:700px)": {
            padding: "5px 15px",
        },
    },
}))

const ModalSendDocument = ({ relacion }) => {

  
    const classes = useStyles();
    const [modal, setModal] = useState(true)
    const dispatch = useDispatch()

    const history = useHistory();
    const firmarAhora = () => {
        setModal(false)
        dispatch(cleanFiles())
        dispatch(resetCF())
        dispatch(modalSendMessage(false))
        history.push(`/circuito/${relacion}`);
    }

    const closeModal = () => {
        setModal(false)
        dispatch(cleanFiles())
        dispatch(resetCF())
        dispatch(modalSendMessage(false))
        history.push('/home');
    }
    const states = useSelector(state => state)
    const radicado = states.CircuitoFirmasReducer.radicado
    const firmantes = states.CircuitoFirmasReducer.firmantes;
	const userInfo = states.auth.userInfo;

	const creator = firmantes.find(
			firmante => firmante.Correo === userInfo.email
		);
	const isObserver = creator && creator.esObservador === 1;

    return (
			<Modal
				open={modal}
				onClose={closeModal}
				disableBackdropClick
				aria-labelledby='simple-modal-title'
				aria-describedby='simple-modal-description'
			>
				<div className={classes.modal}>
					<img src={avion} className={classes.img} />
					<h2
						style={{
							color: '#E55200',
							fontSize: '24px',
							fontFamily: 'Mulish',
							marginBottom: '0',
							padding: ' 0 60px',
						}}
					>
						¡Tu documento se ha enviado!{' '}
					</h2>
					<p className={classes.pSize}>
						El número de radicado de tu documento es el <b>{radicado}</b>,
						podrás conocer el detalle en la sección "Mis circuitos de firmas"
					</p>
					<h3 className={classes.pSizes}>
						{!isObserver && (
							<h3 className={classes.pSizes}>Es tu turno de firmar</h3>
						)}
					</h3>
					<div className={classes.flx}>
						<Button onClick={closeModal} className={classes.add}>
							{isObserver ? 'Ver después' : 'Firmar después'}
						</Button>
						<Button onClick={firmarAhora} className={classes.continue}>
							{isObserver ? 'Ver ahora' : 'Firmar ahora'}
						</Button>
					</div>
				</div>
			</Modal>
		);
}

export default ModalSendDocument