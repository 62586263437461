import React, { useState } from 'react';
import avion from '../../../../assets/img/sendDocument.png';
import { Button, makeStyles, Modal } from '@material-ui/core';
import { cleanFiles, filesCf, modal_signers_invitation, resetCF } from '../../../../redux/actions/CircuitoFirmasAction';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { borrarIndexedDB } from '../../../../redux/actions/indexedDB';

const useStyles = makeStyles((theme) => ({
    modal: {
        position: "absolute",
        backgroundColor: "white",
        boxShadow: '0px 3px 0px #00000033 !important',
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        borderRadius: '10px',
        width: "614px",
        height: "345px",
        paddingBottom: "30px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        "&:focus": {
            outline: "none"
        },
        "@media(max-width:700px)": {
            width: "350px",
            height: "400px",
        },
        "@media(max-width:400px)": {
            width: "300px",
            height: "400px",
        },
    },
    pSize: {
        fontSize: "16px",
        padding: " 0 35px",
        fontFamily: "Mulish",
        textAlign: "center",
        marginTop: "20px",
    },
    flx: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "32px",
        marginTop: "10px",
        paddingBottom: "10px"
    },
    img: {
        marginTop: "45px",
        marginBottom: "12px",
        "@media(max-width:700px)": {
            marginTop: "0px",
        },
    },
    continue: {
        backgroundColor: "#E55200",
        color: "white",
        textTransform: "none",
        borderRadius: "10px",
        fontWeight: "bold",
        border: "none !important",
        fontWeight: "bold !important",
        fontSize: "16px !important",
        padding: "5px 25px",
        "&:hover": {
            backgroundColor: "#E55200",
        },
        "@media(max-width:700px)": {
            padding: "5px 15px",
        },
    },
}))

const ModalSignersInvitation = () => {
    const classes = useStyles();
    const [modal, setModal] = useState(true)
    const history = useHistory();

    const closeModal = () => {
        setModal(false)
        dispatch(cleanFiles())
        dispatch(resetCF())
        dispatch(modal_signers_invitation(false))
        history.push('/home');
    }

    const dispatch = useDispatch()
    const states = useSelector(state => state)
    const radicado = states.CircuitoFirmasReducer.radicado

    return (
        <Modal
            open={modal}
            onClose={closeModal}
            disableBackdropClick
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description">
            <div className={classes.modal}>
                <img src={avion} className={classes.img} />
                <h2 style={{ color: '#E55200', fontSize: "24px", fontFamily: "Mulish", marginBottom: "0", padding: " 0 60px", }}>¡Genial! </h2>
                <p className={classes.pSize}>Hemos iniciado el envío de tu solicitud de firma con el radicado<b> No. {radicado}</b>, podrás conocer el detalle en la sección "Mis circuitos de firmas"</p>
                <div className={classes.flx}>
                    <Button onClick={() => closeModal()} className={classes.continue}>Entendido</Button>
                </div>
            </div>
        </Modal>
    )
}
export default ModalSignersInvitation