import React, { useEffect, useState } from 'react';
import menuIcon from '../../assets/img/iconMenu.png';
import { ReactComponent as IconDocs } from '../../assets/img/iconDocs.svg';
import { ReactComponent as IconSign } from '../../assets/img/IconSign.svg';
import { ReactComponent as IconPlan } from '../../assets/img/plan.svg';
import { connect, useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Avatar from '@material-ui/core/Avatar';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import ModalCertificates from './ModalCertificates';
import logo from '../../assets/img/logo_firmaya.png';
import jwt_decode from 'jwt-decode'
import '../../assets/styles/Styles.css'
import { getLettersAvatar } from '../../helpers/authUtils';
import '../../assets/styles/Styles.css'
import { Capitalize } from '../../helpers/functions'
import {
	SuiteBar,
	UserMenu,
} from "@dg-bucaramanga/react-components-dg-qa";
import { Button, Divider, Grid, Modal, Paper, Tab, Tabs, Typography } from '@material-ui/core';
import { setCurrentPathAction } from '../../redux/actions/HomeAction'
import Sign from '../../views/Firmar/FirmaAvanzada/Sign';
import SvgIcon from '@material-ui/core/SvgIcon';
import { SPECIFIC_USERS, URL_LICENCIAMIENTO } from '../../redux/types/ApiConstants';
import { cleanFiles, miniResetCF, resetCF, stepCircuit } from '../../redux/actions/CircuitoFirmasAction';
import { RESET_CF } from '../../redux/types/CircuitoFirmasTypes';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		minHeight: 0,
		margin: 0,
	},
	appBar: {
		backgroundColor: '#F1F1F1',
	},
	contentBar: {
		Width: '100%',
		padding: '0px',
		marginLeft: '4%',
		marginRight: '5%',
	},
	logo: {
		maxWidth: 147,
		marginRight: 60,
		float: 'left',
	},
	avatar: {
		marginTop: '10px',
		marginBottom: '10px',
	},
	userName: {
		color: '#8a8a8a',
		fontSize: '0.75rem',
		marginRight: '10px',
		marginTop: '14px',
		marginLeft: '40px',
		color: '#4F4F4F',
		fontWeight: "bold",
	},
	paper: {
		margin: 'auto',
		minHeight: '305px',
		maxHeight: '305px',
		display: 'flex-wrap',
		alignItems: 'center',
		justifyContent: 'center',
	},
	avatarOptions: {
		marginTop: '-6px',
		height: '150px',
		backgroundColor: '#F3F3F3',
	},
	avatarSize: {
		width: '70px !important',
		height: '70px !important',
		margin: 'auto',
	},
	title: {
		color: '#E55200',
		marginTop: 5,
		textAlign: 'center',
	},
	dialogC: {
		width: '620px',
		margin: 'auto',
	},
	customWidth: {
		'& div': {
			width: '80% !important',
			maxWidth: '350px',
			height: '100%'
		}
	},
	listItem: {
		color: '#706F6F',
		'&:hover': {
			color: '#E55200',
		},
	},
	listText: {
		marginLeft: '15px'
	},
	menu: {
		marginLeft: 'auto',
		marginRight: '0',
	}
}));

const CheckIcon = (props) => {
	return (
		<SvgIcon {...props}>
			<path d="M14.7,3.375H8.406A1.656,1.656,0,0,0,6.75,5.031V18.946A1.656,1.656,0,0,0,8.406,20.6h9.939A1.656,1.656,0,0,0,20,18.946V8.676Zm-.663,5.963V4.7l4.638,4.638Z" />
		</SvgIcon>
	);
}

const SignIcon = (props) => {
	return (
		<SvgIcon {...props}>
			<path d="M3.849,22.071c-0.404-0.003-0.801-0.105-1.156-0.299c-0.742-0.434-1.116-1.299-0.922-2.137
	c0.257-2.535,3.221-6.805,6.132-8.66l0.072-0.272c0.152-0.573,0.3-1.138,0.451-1.691c-2.432,0.855-4.945,1.46-7.5,1.805
	c-0.449,0.057-0.858-0.261-0.916-0.709c0-0.004-0.001-0.008-0.001-0.011C-0.06,9.643,0.25,9.218,0.703,9.143
	C3.549,8.772,6.336,8.042,8.998,6.97c1.053-3.61,2.177-6.361,3.926-6.877c0.955-0.225,1.955,0.11,2.582,0.865
	c0.601,0.61,0.795,1.512,0.5,2.315c-0.49,1.621-2.374,3.45-5.669,4.961C10.157,8.868,9.983,9.51,9.814,10.14
	c1.281-0.321,2.623,0.213,3.333,1.326c0.264,0.402,0.409,0.871,0.418,1.354c1.252-1.2,2.737-2.211,3.899-1.784
	c0.441,0.15,0.795,0.486,0.969,0.919c0.372,0.929-0.143,2.066-0.555,2.98l-0.01,0.021c0.295-0.155,0.681-0.378,1.155-0.67
	c1.054-0.65,5.336-3.869,5.856-4.32c0.029-0.032,0.06-0.063,0.093-0.09c0.335-0.281,0.835-0.238,1.116,0.097
	c0.009,0.011,0.018,0.022,0.027,0.034c0.147,0.194,0.207,0.441,0.165,0.681c-0.071,0.41-0.073,0.418-2.924,2.544
	c-0.628,0.469-2.735,2.035-3.5,2.508c-1.463,0.9-2.976,1.832-3.765,0.492c-0.336-0.57-0.047-1.209,0.318-2.016
	c0.262-0.494,0.441-1.029,0.531-1.582l-0.018-0.007c-0.525-0.2-2.537,1.429-4.336,3.815c-0.251,0.342-0.729,0.425-1.081,0.188
	c-0.354-0.246-0.473-0.715-0.279-1.1c0.544-1.086,0.929-2.615,0.611-3.072c-0.311-0.525-0.917-0.801-1.517-0.691
	c-0.358,0.061-0.705,0.181-1.025,0.354c-1.277,4.792-2.454,8.806-4.511,9.75C4.493,22.005,4.173,22.074,3.849,22.071z M7.167,13.677
	c-1.547,1.436-2.754,3.198-3.533,5.159c-0.348,0.959-0.253,1.369-0.211,1.419c0.205,0.142,0.468,0.165,0.695,0.063
	c1.129-0.517,2.157-3.494,3.046-6.641H7.167z M13.509,1.695c-0.048,0-0.096,0.006-0.143,0.02c-0.833,0.246-1.629,2.033-2.358,4.274
	c2.079-1.167,3.145-2.388,3.445-3.228c0.063-0.188,0.043-0.395-0.055-0.568c-0.203-0.295-0.531-0.48-0.889-0.502V1.695z" />
		</SvgIcon>
	);
}

const MenuIcon = () => {
	return (<img style={{ marginTop: '8px', marginBottom: '8px' }} src={menuIcon} />);
}

const CertificatesIcon = (props) => {
	return (
		<SvgIcon {...props}>
			<path d="M10.15,9.396c0,1.899-1.539,3.439-3.438,3.439s-3.438-1.54-3.438-3.439c0-1.898,1.539-3.438,3.438-3.438
		S10.15,7.497,10.15,9.396z M3.901,14c0,0.168,0.053,0.32,0.126,0.459l0.495-1.458c-0.223-0.137-0.428-0.299-0.621-0.473V14z
		 M23.901,0h-19c-0.552,0-1,0.448-1,1v5.339C4.643,5.669,5.616,5.25,6.695,5.25c2.313,0,4.188,1.875,4.188,4.188
		c0,1.468-0.759,2.755-1.903,3.503L9.679,15h14.222c0.553,0,1-0.449,1-1V1C24.901,0.448,24.453,0,23.901,0z M12.443,4.079h6v1h-6
		V4.079z M21.526,10.995h-9.083v-1h9.083V10.995z M21.526,8.078h-9.083v-1h9.083V8.078z M10.132,9.397
		c0,1.898-1.539,3.438-3.438,3.438s-3.438-1.539-3.438-3.438c0-1.899,1.539-3.438,3.438-3.438S10.132,7.498,10.132,9.397z
		 M9.978,17.913l-3.165-0.993l-3.246,1.018l1.553-4.578c0.453,0.309,0.999,0.489,1.587,0.489c0.639,0,1.223-0.22,1.696-0.578
		L9.978,17.913z" />
		</SvgIcon>
	);
}

const Home = (props) => {
	const classes = useStyles();
	const userInfo = useSelector(({ auth }) => auth.userInfo);
	const userIn = {
		user: {
			enterprise: "true",
			email: "",
			name: "",
			role: [""],
			profilePicture: "",
			closeSession: () => {
				logout();
			},
		},
	};
	const history = useHistory();
	const [modalCertificates, setModalCertificates] = useState(false);
	const [token, setToken] = useState({
		result: {
			token: "",
		},
		error: {},
	});
	const dataSesion = jwt_decode(localStorage.getItem("JWT"));
	const [profileMenu, setProfileMenu] = useState(null);
	const [optionsMenu, setOptionsMenu] = useState(null);
	const [open, setOpen] = useState(false);
	const [signPredefined] = useState(null);
	const [showComplete] = useState(false);
	const [isMenuVertical, setMenuVertical] = useState(window.innerWidth <= 1200 ? false : true);
	const dispatch = useDispatch()

	useEffect(() => {
		(async () => {
			await getToken();
		})()
	}, []);

	const getToken = (currentToken) => {
		let jwt;
		if (currentToken) {
			jwt = currentToken;
		} else {
			;
			jwt = localStorage.getItem("JWT")
		}

		if (jwt) {
			const jwtToken = jwt.replace("Bearer ", "");
			setToken((oldState) => ({
				...oldState,
				result: {
					...oldState.result,
					token: jwtToken,
				},
			}));
			return true;
		} else {
			setTimeout(getToken, 1000);
			return false;

		}
	};

	const handleClickOpen = () => {
		setProfileMenu(null);
		setOpen(true);
		setOptionsMenu(false);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleCloseCertificates = () => {
		setModalCertificates(false);
	};

	const logout = () => {
		localStorage.removeItem('JWT');
		setProfileMenu(null);
		history.push('/');
	};

	const handleCertificates = () => {
		setProfileMenu(null);
		setOptionsMenu(false);
	};

	const handleClickLogo = () => {
		props.setCurrentPathAction('false');
		dispatch(stepCircuit(0))
		dispatch(resetCF())
		dispatch(cleanFiles())
		localStorage.removeItem('irHome')
	}

	const handleClickMiPlan = () => {
		window.open(URL_LICENCIAMIENTO + '/myplan/' + localStorage.JWTD);
	};

	window.addEventListener('resize', function (event) {
		if (this.window.innerWidth <= 1200) {
			setMenuVertical(false);
			setProfileMenu(false);
		}
		else {
			setMenuVertical(true);
			setOptionsMenu(null);
		}
	}, true);

	return (
		<>
			<AppBar position="static" className={classes.appBar}>
				<div className={classes.contentBar}>
					<Toolbar className={classes.root}>
						<Link to={userInfo.userDigitalWare? "/home/CircuitoFirmas" : "/home"} >
							<img src={logo} height="40px" alt="logo" className={classes.logo} onClick={() => handleClickLogo()} />
						</Link>
						{/*<Tabs value={current_path} TabIndicatorProps={{ style: { backgroundColor: '#E55200' } }} textColor="primary">
							<Tab value="/home/firmar" className={'tabItems'} label="Firmar documentos" component={Link} to="/home/firmar" />
							<Tab value="/home/validate" className={'tabItems'} label="Verificar documentos" component={Link} to="/home/validate" />
							<Tab value="/home/ConsultSignedDocument" className={'tabItems'} label="Mis documentos" component={Link} to="/home/ConsultSignedDocument" />
						</Tabs>*/}
						<div className={classes.menu}>
							{
								isMenuVertical ? (
									<Grid className={classes.avatar} container spacing={0} direction="row">
										<Grid item><SuiteBar appName="firmaya" scrollWidth={6} token={token.result.token} /></Grid>
										<Grid item className={classes.userName}>{Capitalize(dataSesion.given_name + ' ' + dataSesion.family_name)}</Grid>
										{token.result.token &&
											<Grid item style={{ marginTop: '4px' }}><UserMenu
												key={"useMenu"}
												appName="firmaya"
												userInfo={userIn}
												setTokens={setToken}
												functions={[{ name: "handleClickOpen", function: handleClickOpen }, { name: "handleClickMiPlan", function: handleClickMiPlan }]}
												viewLoader={false}
												tokens={token}
											/></Grid>}
									</Grid>
								) : (
									<IconButton
										id="options-info"
										style={{ marginRight: '-10px' }}
										aria-haspopup="true"
										onClick={(e) => setOptionsMenu(e.currentTarget)}
										aria-controls="options-menu"
										color="inherit"
									>
										<MenuIcon style={{ color: '#E55200' }} />
									</IconButton>
								)
							}
							<Menu
								id="profile-menu"
								autoFocus={true}
								open={Boolean(profileMenu)}
								anchorEl={profileMenu}
								getContentAnchorEl={null}
								anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
								transformOrigin={{ vertical: 'top', horizontal: 'center' }}
								onClose={() => setProfileMenu(null)}
							>
								<MenuItem
									onClick={() => handleCertificates()}
									className={classes.listItem}
									component={Link} to="/home/certificados"
								>
									<CertificatesIcon />
									<Typography className={classes.listText}> Mis certificados</Typography>
								</MenuItem>
								<Divider variant="middle" />
								<MenuItem
									onClick={handleClickOpen}
									className={classes.listItem}
								>
									<SignIcon />
									<Typography className={classes.listText}> Mis firmas</Typography>
								</MenuItem>
								<Divider variant="middle" />
								<MenuItem
									onClick={handleClickMiPlan}
									className={classes.listItem}
								>
									<IconPlan />
									<Typography className={classes.listText}> Mi plan</Typography>
								</MenuItem>
								<Divider variant="middle" />
								<MenuItem
									onClick={() => logout()}
									className={classes.listItem}
								>
									<PowerSettingsNewIcon />
									<Typography className={classes.listText}> Cerrar sesión</Typography>
								</MenuItem>
							</Menu>
							<Menu
								open={Boolean(optionsMenu)}
								autoFocusItem={false}
								autoFocus={false}
								id="options-menu"
								anchorReference="anchorPosition"
								marginThreshold={0}
								anchorPosition={{ top: '-1px', left: 1000 }}
								getContentAnchorEl={20}
								onClose={() => setOptionsMenu(null)}
								className={'styleUser'}
							>
								<MenuItem className={classes.avatarOptions}>
									<Avatar className={classes.avatarSize}>{getLettersAvatar(userInfo)}</Avatar>
								</MenuItem>
								<MenuItem
									className={classes.listText}
									component={Link} to="/home/firmar"
									onClick={() => setOptionsMenu(false)}
									style={{ marginTop: '20px' }}
								>
									<SignIcon />
									<Typography className={classes.listText}>Firmar documentos</Typography>
								</MenuItem>
								<MenuItem
									className={classes.listText}
									component={Link} to="/home/validate"
									onClick={() => setOptionsMenu(false)}
									style={{ marginLeft: '19px' }}
								>
									<IconDocs />
									<Typography className={classes.listText}>Verificar documentos</Typography>
								</MenuItem>
								<MenuItem
									className={classes.listText}
									component={Link} to="/home/ConsultSignedDocument"
									onClick={() => setOptionsMenu(false)}
									style={{ marginBottom: '10px' }}
								>
									<CheckIcon />
									<Typography className={classes.listText}>Mis documentos</Typography>
								</MenuItem>
								<Divider variant="middle" />
								<MenuItem
									onClick={() => handleCertificates()}
									className={classes.listText}
									style={{ marginTop: '10px' }}
								>
									<CertificatesIcon />
									<Typography className={classes.listText}> Mis certificados</Typography>
								</MenuItem>
								<MenuItem
									onClick={handleClickOpen}
									className={classes.listText}
									style={{ marginBottom: '10px' }}
								>
									<IconSign />
									<Typography className={classes.listText}> Mis firmas</Typography>
								</MenuItem>
								<MenuItem
									onClick={handleClickMiPlan}
									className={classes.listText}
									style={{ marginBottom: '10px' }}
								>
									<IconPlan />
									<Typography className={classes.listText}> Mi plan</Typography>
								</MenuItem>
								<Divider variant="middle" />
								<MenuItem
									onClick={() => {
										logout()
										setOptionsMenu(false)
									}}
									className={classes.listText}
									style={{ marginTop: '10px', marginBottom: '10px' }}
								>
									<PowerSettingsNewIcon />
									<Typography className={classes.listText}> Cerrar sesión</Typography>
								</MenuItem>
								<Divider variant="middle" />
							</Menu>
						</div>
					</Toolbar>
				</div>
			</AppBar>
			{
				open && (
					<Modal
						open={open}
						aria-labelledby="simple-modal-title"
						aria-describedby="simple-modal-description"
						onClose={() => { handleClose(false) }}
						className="modal-sign"
						disableBackdropClick
					>
						<Sign
							signPredefined={signPredefined}
							showComplete={showComplete}
							setStateFirmas={handleClose}
						/>
					</Modal>
				)
			}
			{
				modalCertificates && (
					<Modal
						open={modalCertificates}
						onClose={handleCloseCertificates}
					>
						<ModalCertificates
							isOpen={modalCertificates}
							onClose={() => setModalCertificates(false)}
							setModalCertificates={setModalCertificates}
						/>
					</Modal>
				)
			}
		</>
	);
};

const mapStateToProps = (state) => ({
	homeReducer: state.home
});

const mapDispatchToProps = {
	setCurrentPathAction
};
export default connect(mapStateToProps, mapDispatchToProps)(Home);