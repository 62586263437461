import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import UploadFileIcon from '@mui/icons-material/UploadFile'; // Importa el ícono para cargar documentos
import EditIcon from '@mui/icons-material/Edit'; // Importa el ícono para incluir campos de firma
import EmailIcon from '@mui/icons-material/Email'; // Importa el ícono para configurar mensaje
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import Trazado0 from "../assets/img/Trazado0.svg"
import Trazado1 from '../assets/img/Trazado1.svg';
import add1 from '../assets/img/add1.svg';
import message1 from '../assets/img/message1.svg';
import message0 from '../assets/img/message0.svg';
import step1 from '../assets/img/step1.svg';


import StepConnector, {
	stepConnectorClasses,
} from '@mui/material/StepConnector';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from 'styled-components';
const StyledStepLabel = styled(StepLabel)(({ theme, ownerState }) => ({
	'& .MuiStepLabel-label': {
		fontWeight: 'bold !important', // Establece el peso de la fuente en negrita
		fontFamily: '"Muli", "Roboto", "Helvetica", "Arial", sans-serif !important', // Usa Muli como principal y Roboto como secundaria
		color: ownerState.completed
			? '#0294C2 !important'
			: theme.palette.text.secondary, // Color para completados
		...(ownerState.active && {
			color: '#0294C2 !important', // Color para el paso activo
		}),
	},
}));

const QontoConnector = styled(StepConnector)(({ theme }) => ({
	[`&.${stepConnectorClasses.alternativeLabel}`]: {
		top: 10,
		left: 'calc(-50% + 16px)',
		right: 'calc(50% + 16px)',
	},
	[`&.${stepConnectorClasses.active}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			borderColor: '#784af4',
		},
	},
	[`&.${stepConnectorClasses.completed}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			borderColor: '#784af4',
		},
	},
	[`& .${stepConnectorClasses.line}`]: {
		borderColor:
			theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
		borderTopWidth: 3,
		borderRadius: 1,
	},
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
	color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
	display: 'flex',
	height: 22,
	alignItems: 'center',
	...(ownerState.active && {
		color: '#784af4',
	}),
	'& .QontoStepIcon-completedIcon': {
		color: '#784af4',
		zIndex: 1,
		fontSize: 18,
	},
	'& .QontoStepIcon-circle': {
		width: 8,
		height: 8,
		borderRadius: '50%',
		backgroundColor: 'currentColor',
	},
}));

function QontoStepIcon(props) {
	const { active, completed, className } = props;

	return (
		<QontoStepIconRoot ownerState={{ active }} className={className}>
			{completed ? (
				<Check className='QontoStepIcon-completedIcon' />
			) : (
				<div className='QontoStepIcon-circle' />
			)}
		</QontoStepIconRoot>
	);
}

QontoStepIcon.propTypes = {
	/**
	 * Whether this step is active.
	 * @default false
	 */
	active: PropTypes.bool,
	className: PropTypes.string,
	/**
	 * Mark the step as completed. Is passed to child components.
	 * @default false
	 */
	completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
	[`&.${stepConnectorClasses.alternativeLabel}`]: {
		top: 22,
	},
	[`&.${stepConnectorClasses.active}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			backgroundColor: '#0294C2',
		},
	},
	[`&.${stepConnectorClasses.completed}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			backgroundColor: '#0294C2',
		},
	},
	[`& .${stepConnectorClasses.line}`]: {
		height: 2,
		border: 0,
		backgroundColor:
			theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#DEDEDE',
		borderRadius: 1,
	},
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
	backgroundColor:
		theme.palette.mode === 'dark' ? theme.palette.grey[700] : 'white',
	zIndex: 1,
	border: '2px solid #DEDEDE',
	color: '#fff',
	width: 40,
	height: 40,
	display: 'flex',
	borderRadius: '50%',
	justifyContent: 'center',
	alignItems: 'center',
	...(ownerState.active && {
		backgroundColor: '#0294C2',
		boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
		border: 'none',
	}),
	...(ownerState.completed && {
		backgroundColor: '#0294C2',
		border: 'none',
	}),
}));

function ColorlibStepIcon(props) {
	const { active, completed, icon } = props;

	// Determina qué SVG usar para cada paso basado en su estado
	const getStepIcon = iconNumber => {
		if (iconNumber === 3) {
			// Asumiendo que quieres cambiar el ícono del segundo paso
			return active || completed ? (
				<img src={Trazado1} alt='Active Custom Icon' />
			) : (
				<img src={Trazado0} alt='Inactive Custom Icon' />
			);
		}
		if (iconNumber === 4) {
			// Asumiendo que quieres cambiar el ícono del segundo paso
			return active || completed ? (
				<img src={message1} alt='Active Custom Icon' />
			) : (
				<img src={message0} alt='Inactive Custom Icon' />
			);
		}
		// Define los íconos predeterminados para otros pasos
		const defaultIcons = {
			1: <img src={step1} alt='ste´1' />,
			2: <img src={add1} alt='Custom Icon' />, // Este será reemplazado por la lógica de arriba
			3: <EditIcon />,
			4: <EmailIcon />,
		};
		return defaultIcons[iconNumber];
	};

	return (
		<ColorlibStepIconRoot ownerState={{ completed, active }}>
			{getStepIcon(icon)}
		</ColorlibStepIconRoot>
	);
}

const steps = [
	'Cargar documentos',
	'Agregar firmantes',
	'Incluir campos de firma',
	'Configurar mensaje',
];

export default function CustomizedSteppers({ activeStep }) {
	const theme = useTheme(); // Usa esta línea para obtener el tema actual

	// Este es el componente Stack con estilos responsivos

	const ResponsiveStack = styled(Stack)(({ theme }) => ({
		position: 'relative',
		zIndex: '0',
		width: '100%', // En pantallas pequeñas ocupa el 100% del ancho
		marginLeft: 0, // Elimina el margen izquierdo en pantallas pequeñas
		[theme.breakpoints.up('sm')]: {
			position: 'relative',
			zIndex: '0',
			width: '60%', // En pantallas más grandes, ocupa el 50% del ancho
			marginLeft: '20%', // Y centra el componente ajustando el margen izquierdo
		},
	}));

	return (
		<ResponsiveStack spacing={2}>
			<Stepper
				alternativeLabel
				activeStep={activeStep}
				connector={<ColorlibConnector />}
			>
				{steps.map((label, index) => (
					<Step key={label}>
						<StyledStepLabel
							StepIconComponent={ColorlibStepIcon}
							ownerState={{
								active: index === activeStep,
								completed: index < activeStep,
							}}
						>
							{label}
						</StyledStepLabel>
					</Step>
				))}
			</Stepper>
		</ResponsiveStack>
	);
}
