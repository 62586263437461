import { makeStyles, Modal } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ups from '../../assets/img/ups.png';
import {
	API_HOSTNAME_AWS,
	API_HOSTNAME_SIGNS,
} from '../../redux/types/ApiConstants';
import { deleteFile } from '../../redux/actions/ConsultSignedDocumentsAction';
import {
	deleteAction,
	noAction,
} from '../../redux/actions/CircuitoFirmasAction';
import { helpHttp } from '../../helpers/helpHttp';
import axios from 'axios';

const useStyle = makeStyles({
	modal: {
		margin: 0,
		padding: '40px',
		position: 'absolute',
		backgroundColor: 'white',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		boxShadow: '0px 3px 0px #00000033 !important',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		borderRadius: '10px',
		width: '434px',
		height: '336px',
		'&:focus': {
			outline: 'none',
		},
	},
	title: {
		color: '#E55200',
		fontSize: '24px',
		fontFamily: 'Mulish',
		fontWeight: '700',
		margin: '20px 0 0 0',
		lineHeight: '30px',
	},
	flex: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		gap: '16px',
		marginTop: '40px',
	},
	parrafo: {
		fontSize: '16px',
		textAlign: 'center',
		margin: '24px 0 0 0',
	},
	continue: {
		backgroundColor: '#E55200',
		color: 'white',
		padding: '8px 24px',
		fontSize: '16px',
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
		fontWeight: '800',
		fontFamily: 'Mulish',
		border: '1px solid #E55200',
		borderRadius: '8px',
		'&:hover': {
			backgroundColor: '#E55200',
		},
	},
	btnCerrar: {
		color: '#E55200',
		padding: '8px 24px',
		fontSize: '16px',
		cursor: 'pointer',
		border: '1px solid #E55200',
		borderRadius: '8px',
		backgroundColor: 'transparent',
		fontFamily: 'Mulish',
		fontWeight: '700',
		margin: 0,
	},
});

const ModalDeleteFiles = props => {
	const {
		modalDeleteFiles,
		setModalDeleteFiles,
		setSelected,
		selected,
		rows,
		setLoading,
		refresh,
		idDocsSelected,
		idCarpetCircuit,
		setOpenedRowIndex,
		user
	} = props;
	const dispatch = useDispatch();
	const states = useSelector(state => state);
	const { enqueueSnackbar } = useSnackbar();
	const jwt = localStorage.getItem('JWT');
	const classes = useStyle();

	const closeModal = () => {
		setModalDeleteFiles(false);
	};
	const deleteDocument = async id => {
		try {
			const response = await axios.post(
				`${API_HOSTNAME_SIGNS}api/sign/deleteDocument`,
				id
			);
			if (response.data.codigoRespuesta === 'RS1')
				showNotification(response.data.descripcionRespuesta, 'success');
			return response.data.httpStatus;
		} catch (error) {
			showNotification('No fue posible eliminar el archivo', 'error');
			return 400;
		}
	};
//Falta traer el idFirmante para realizar la accion de eliminar
	const deleteCircuito = async id => {
		try {
			const response = await fetch(
				`${API_HOSTNAME_AWS}webCircuit/api/UpdateCircuit`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${jwt}`,
					},
					body: JSON.stringify({
						circuito: id,
						firmante: Number(`${user}`),
						eliminarLista: true
					}),
					redirect: 'follow',
				}
			);

			const res = await response.json();
			if (res.httpStatus === 200)
				showNotification('Circuito eliminado de tus documentos.', 'success');
			return res.httpStatus;
		} catch (error) {
			showNotification('No fue posible eliminar el circuito.', 'error');
			return 400;
		}
	};

	const showNotification = (message, variant) => {
		enqueueSnackbar(message, {
			variant,
			anchorOrigin: { vertical: 'top', horizontal: 'right' },
		});
	};

	const handleDelete = async (idsCircuitos, idsDocumentos, refresh) => {
		setLoading(true);
		setOpenedRowIndex(-1)
		closeModal();

		try {
			const promises = [
				...idsDocumentos.map(async id => deleteDocument([id])),
				...idsCircuitos.map(async id => deleteCircuito(id)),
			];

			const results = await Promise.all(promises);
			const totalDeleted = results.filter(result => result === 200).length;
			if (totalDeleted === idsCircuitos.length + idsDocumentos.length) {
				showNotification(
					'Todos los elementos seleccionados fueron eliminados exitosamente.',
					'success'
				);
			} else {
				if(totalDeleted>0 && totalDeleted !== idsCircuitos.length + idsDocumentos.length){
					showNotification(
						`Algunos archivos no pudieron ser eliminados `,
						`error`
					);
				}else{
					showNotification(
						`Ocurrio un errror al eliminar los archivos, porfavor intentelo nuevamente`,
						`error`
					);
				}
			}
		} catch (error) {
			showNotification(
				'Ocurrió un error al eliminar los elementos seleccionados.',
				'error'
			);
		} finally {
			setSelected([]);
			setLoading(false);
			refresh(true);
		}
	};
	return (
		<Modal open={modalDeleteFiles} onClose={closeModal} disableBackdropClick>
			<div className={classes.modal}>
				<img src={ups} alt='ups' />
				<h4 className={classes.title}>Eliminar documentos</h4>
				<p className={classes.parrafo}>
					¿Estas seguro de eliminar de manera permanente los documentos
					seleccionados?
				</p>
				<div className={classes.flex}>
					<button
						onClick={() =>
							handleDelete(idCarpetCircuit, idDocsSelected, refresh)
						}
						className={classes.btnCerrar}
					>
						Si
					</button>
					<button onClick={() => closeModal()} className={classes.continue}>
						No
					</button>
				</div>
			</div>
		</Modal>
	);
};

export default ModalDeleteFiles;
