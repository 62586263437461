import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MenuItem } from 'react-contextmenu';
import lapiz from '../../../assets/img/Icon-material-edit.png';
import {v1} from "uuid"
import { validateEmail } from '../../../redux/actions/Validaciones';
import { validateName } from '../../../redux/actions/Validaciones';
import Autocomplete, { createFilterOptions} from '@material-ui/lab/Autocomplete';
import { useSnackbar } from 'notistack';
import { Box, Button, Checkbox, Container, Grid, makeStyles, Paper, Select, TextField, Tooltip} from '@material-ui/core';
import add from '../../../assets/img/addUser.svg';
import avatar from '../../../assets/img/avatar.png';
import del from '../../../assets/img/trashOrange.svg';
import folders from '../../../assets/img/Icon-material-folder.png';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import VisibilityIcon from '@material-ui/icons/Visibility';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { capitalize, preventSpaceWhenEmpty, truncateText } from '../../../helpers/formatText';
import { camposHechos, firm_positions, irHome, name_folder, stepCircuit } from '../../../redux/actions/CircuitoFirmasAction';
import { useHistory } from 'react-router-dom';
import CustomizedSteppers from '../../../components/Demo';

const useStyle = makeStyles(theme => ({
	select: {
		width: '170px',
		maxHeight: '6px !important',
		background: 'white',
		color: '#444444',
		fontWeight: 400,
		border: '1px solid #706F6F',
		borderRadius: 8,
		paddingLeft: 8,
		paddingTop: 8,
		paddingBottom: 8,
		paddingRight: 0,
		boxShadow: '0 1px 3px 0 rgba(0,0,0,0.46)',
		'&:focus': {
			borderRadius: 8,
			background: 'white',
		},
		'&.Mui-disabled': {
			opacity: 0.5,
		},
	},
	select2: {
		width: '50px',
		maxHeight: '6px !important',
		background: 'white',
		color: '#444444',
		fontWeight: 400,
		border: '1px solid #706F6F',
		borderRadius: 8,
		paddingLeft: 8,
		paddingTop: 8,
		paddingBottom: 8,
		paddingRight: 0,
		boxShadow: '0 1px 3px 0 rgba(0,0,0,0.46)',
		'&:focus': {
			borderRadius: 8,
			background: 'white',
		},
		'&.Mui-disabled': {
			opacity: 0.5,
		},
	},
	icon: {
		color: 'black',
		right: 12,
		position: 'absolute',
		userSelect: 'none',
		pointerEvents: 'none',
	},
	paper: {
		background: '#ffffff',
		border: '1px solid #9D9D9C',
		boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.2)',
	},
	list: {
		paddingTop: 0,
		paddingBottom: 0,
		background: 'white',
		textAlign: 'center',
		cursor: 'pointer',
		'& div': {
			fontWeight: 400,
			paddingTop: 12,
			paddingBottom: 12,
		},
		'& div:hover': {
			background: '#878787',
			color: 'white !important ',
		},
		'& div.Mui-selected': {
			color: 'black !important',
			background: 'white',
		},
		'& div.Mui-selected:hover': {
			color: 'white !important',
			background: '#878787',
		},
	},
	textFields: {
		'& div:nth-child(2)': {
			backgroundColor: 'white !important',
		},
		'& input': {
			padding: '13px 0 1px 12px !important',
			fontSize: '14px !important',
			'&:-webkit-autofill': {
				'-webkit-text-fill-color': '#000000 !important', // Color del texto
				'-webkit-box-shadow': '0 0 0px 1000px white inset !important', // Fondo del autofill
			},
		},
		'& label': {
			color: '#7B7B7B !important',
			fontSize: '14px !important',
			'&.Mui-focused': {
				color: '#FF9800 !important',
			},
		},
	},
	parrafo: {
		margin: '0',
	},
	size: {
		fontSize: '17px',
		colo: '4F4F4F',
		margin: '0 0 32px 0',
		fontWeight: '700',
	},
	square: {
		width: '36px',
		minWidth: '36px',
		maxWidth: '36px',
		height: '36px',
		border: '1px solid grey',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: '9px',
		boxShadow: '0 1px 3px 0 rgba(0,0,0,0.46)',
		marginLeft: '15px',
	},
	nameDocuments: {
		fontSize: '16px',
		width: '100%',
		margin: '0 0 0 5px',
		backgroundColor: 'transparent',
		display: 'flex',
		border: 'none',
		fontWeight: 'bold',
		'& input': {
			margin: '0 10px 0 5px',
			backgroundColor: 'transparent',
			display: 'flex',
			border: 'none',
			fontWeight: 'bold',
			fontSize: '16px',
		},
	},
	containerFirmantes: {
		display: 'flex',
		minWidth: '860px',
		gap: '15px',
		padding: '15px 10px',
		alignItems: 'flex-start', // Asegura que los elementos estén alineados verticalmente
		cursor: 'move',
		borderBottom: '1px solid #B2B2B2',
		'& button': {
			opacity: '0',
			pointerEvents: 'none',
			padding: '0',
			display: 'inline',
			border: 'none',
		},
		'&:hover': {
			border: '1px solid #7B7B7B',
			'& button': {
				opacity: '1',
				pointerEvents: 'auto',
				backgroundColor: 'transparent',
				'&:hover': {
					backgroundColor: '#F1EAEA',
					cursor: 'pointer',
				},
			},
		},
	},
	textField: {
		[`& fieldset`]: {
			borderRadius: '10px',
			border: '1px solid #706F6F',
			boxShadow: '0 1px 3px 0 rgba(0,0,0,0.46)',
		},
		['& input']: {
			'&:-webkit-autofill': {
				'-webkit-text-fill-color': '#000000 !important', // Color del texto
				'-webkit-box-shadow': '0 0 0px 1000px white inset !important', // Fondo del autofill
			},
		},
	},
	containerBtns: {
		display: 'flex',
		justifyContent: 'space-between',
		alignContent: 'center',
		marginTop: '20px',
		'@media(max-width:500px)': {
			width: '100%',
			height: '62px',
		},
	},
	inputRoots: {
		fontSize: '14px !important',
		fontFamily: 'Mulish',
		backgroundColor: 'white',
		border: '1px solid #706F6F',
		borderRadius: '10px',
		boxShadow: '0 1px 3px 0 rgba(0,0,0,0.46)',
		padding: '4px 0 0 0 !important',
		'&:hover': {
			backgroundColor: 'white',
		},
		'&:focus': {
			backgroundColor: '#E55200',
		},
	},
	autocompleteRoot: {
		width: '100%',
	},
	continue: {
		backgroundColor: '#E55200',
		color: 'white',
		textTransform: 'none',
		borderRadius: '10px',
		textShadow: '0px 2px 0px #00000040 !important',
		border: 'none !important',
		marginTop: '14px !important',
		fontWeight: 'bold !important',
		fontSize: '16px !important',
		padding: '5px 18px',
		'&:hover': {
			backgroundColor: '#E55200',
		},
		'&.Mui-disabled': {
			color: 'white',
		},
	},
	add: {
		backgroundColor: 'white',
		color: '#E55200',
		textTransform: 'none',
		borderRadius: '10px',
		border: '1px solid #E55200 !important',
		marginTop: '14px !important',
		fontWeight: 'bold !important',
		fontSize: '16px !important',
		padding: '5px 18px',
		'&:hover': {
			backgroundColor: 'white',
		},
		'@media(max-width:500px)': {
			padding: '5px ',
			height: '41px',
			marginTop: '15px',
		},
	},
	btnDel: {
		marginTop: '8px',
	},
	suggestionName: {
		fontFamily: 'Mulish',
		fontStyle: 'normal',
		fontWeight: 700,
		fontSize: '14px',
		lineHeight: '22px',
	},
	suggestionEmail: {
		color: theme.palette.text.secondary,
		fontFamily: 'Mulish',
		fontStyle: 'normal',
		fontWeight: 500,
		fontSize: '14px',
		lineHeight: '22px',
	},
	opt: {
		backgroundColor: 'white !important',
		width: '229px !important',

		'&:hover': {
			backgroundColor: 'rgba(0, 0, 0, 0.05) !important',
		},
	},
	paper2: {
		width: '230px',
	},
}));

const AddSignersCF = () => {
	const classes = useStyle();
	const dispatch = useDispatch();
	const dragItem = useRef();
	const dragOverItem = useRef();
	const inputRef = useRef(null);
	const [addNewSigner, setAddNewSigner] = useState(false);
	const [btnDisabled, setBtnDisabled] = useState(true);
	const [btnDisabledApellido, setBtnDisabledApellido] = useState(true);
	const [btnDisabledCorreo, setBtnDisabledCorreo] = useState(true);
	const [btnDisabledNombre, setBtnDisabledNombre] = useState(true);
	const [documentos, setDocumentos] = useState([]);
	const [editar, setEditar] = useState(false);
	const [errores, setErrores] = useState([]);
	const [errorExist, setErrorExist] = useState(false);
	const [errorValidationApellido, setErrorValidationApellido] = useState('');
	const [errorValidationCorreo, setErrorValidationCorreo] = useState('');
	const [errorValidationNombre, setErrorValidationNombre] = useState('');
	const [firmantes, setFirmantes] = useState([]);
	const [folderName, setFolderName] = useState('');
	const [textValidationApellido, setTextValidationApellido] = useState('');
	const [textValidationCorreo, setTextValidationCorreo] = useState('');
	const [textValidationNombre, setTextValidationNombre] = useState('');
	const [medida, setMedida] = useState();
	const [camposIncompletos, setCamposIncompletos] = useState(false);
	const [disabledFieldIndex, setDisabledFieldIndex] = useState(-1);
	const { enqueueSnackbar } = useSnackbar();
	const filter = createFilterOptions();
	const initialNewSigner = {
		Id: v1(),
		Posicion: firmantes?.length + 1,
		Nombres: '',
		Apellidos: '',
		Correo: '',
		mensajePriv: '',
		firmanteLibre: true,
		Manuscrita: true,
		esAprobador: 0,
		esObservador: 0, // Nuevo campo
		firmaDocumentos: [],
	};
	const [firmanteNuevo, setFirmanteNuevo] = useState(initialNewSigner);
	const states = useSelector(state => state);
	const userInfo = useSelector(({ auth }) => auth.userInfo);
	const files = states.CircuitoFirmasReducer.files;
	const stepsCircuit =states.CircuitoFirmasReducer.stepCircuit;
	const signers = states.CircuitoFirmasReducer.firmantes;
	const folder = states.CircuitoFirmasReducer.carpeta;
	const camposAnteriores = states.CircuitoFirmasReducer.camposHechos;

	const menuProps = {
		classes: {
			paper: classes.paper,
			list: classes.list,
		},
		anchorOrigin: {
			vertical: 'bottom',
			horizontal: 'left',
		},
		transformOrigin: {
			vertical: 'top',
			horizontal: 'left',
		},
		getContentAnchorEl: null,
	};

	useEffect(() => {
		document.body.className = 'bodyMiddle';
		if (folder) {
			setFolderName(folder.trim());
			setMedida(folder.trim().length * 10);
		}
		if (files) setDocumentos(files);
	}, []);

	useEffect(() => {
		if (stepsCircuit === 10) {
			dispatch(irHome(true));
			localStorage.setItem('irHome', true);
		}
	}, []);

	useEffect(() => {
		if (signers) {
			setFirmantes(signers);
			setAddNewSigner(false);
		}
	}, [signers]);

	useEffect(() => {
		if (firmanteNuevo.Nombres.length < 1) {
			setBtnDisabledNombre(true);
			setErrorValidationNombre(false);
			setTextValidationNombre('');
		}
		if (
			!validateName(firmanteNuevo.Nombres) &&
			firmanteNuevo.Nombres.length >= 1
		) {
			setBtnDisabledNombre(true);
			setErrorValidationNombre(true);
			setTextValidationNombre(
				'Minimo 3 caracteres, no se admiten caracteres especiales'
			);
		}
		if (validateName(firmanteNuevo.Nombres)) {
			setBtnDisabledNombre(false);
			setErrorValidationNombre(false);
			setTextValidationNombre('');
		}
	}, [firmanteNuevo.Nombres]);

	useEffect(() => {
		if (firmanteNuevo.Apellidos.length < 1) {
			setBtnDisabledApellido(true);
			setErrorValidationApellido(false);
			setTextValidationApellido('');
		}
		if (
			!validateName(firmanteNuevo.Apellidos) &&
			firmanteNuevo.Apellidos.length >= 1
		) {
			setBtnDisabledApellido(true);
			setErrorValidationApellido(true);
			setTextValidationApellido(
				'Minimo 3 caracteres, no se admiten caracteres especiales'
			);
		}
		if (validateName(firmanteNuevo.Apellidos)) {
			setBtnDisabledApellido(false);
			setErrorValidationApellido(false);
			setTextValidationApellido('');
		}
	}, [firmanteNuevo.Apellidos]);

	useEffect(() => {
		if (firmanteNuevo.Correo.length < 1) {
			setBtnDisabledCorreo(true);
			setErrorValidationCorreo(false);
			setTextValidationCorreo('');
		}
		if (validateEmail(firmanteNuevo.Correo)) {
			if (
				firmantes?.some(firmante => firmante.Correo === firmanteNuevo.Correo)
			) {
				setBtnDisabledCorreo(true);
				setErrorValidationCorreo(true);
				setTextValidationCorreo(
					'Este email ya pertenece al circuito de firmas'
				);
			} else {
				setBtnDisabledCorreo(false);
				setErrorValidationCorreo(false);
				setTextValidationCorreo('');
			}
		} else if (firmanteNuevo.Correo.length < 1) {
			setErrorValidationCorreo(false);
			setTextValidationCorreo('');
		} else {
			// El correo electrónico es inválido
			setBtnDisabledCorreo(true);
			setErrorValidationCorreo(true);
			setTextValidationCorreo('Escribe un email válido');
		}
	}, [firmanteNuevo.Correo]);

	useEffect(() => {
		if (btnDisabledCorreo || btnDisabledNombre || btnDisabledApellido) {
			setBtnDisabled(true);
		} else {
			setBtnDisabled(false);
		}
	}, [btnDisabledCorreo, btnDisabledNombre, btnDisabledApellido]);

	useEffect(() => {
		if (!addNewSigner) {
			setBtnDisabled(false);
		} else {
			setBtnDisabled(true);
		}
	}, [addNewSigner]);

	useEffect(() => {
		if (firmantes.length === 0 && !addNewSigner) {
			setAddNewSigner(true);
			setFirmanteNuevo(initialNewSigner);
		}
	}, [firmantes]);

	useEffect(() => {
		if (editar) {
			inputRef.current.select();
			inputRef.current.focus();
		}
	}, [editar]);

	useEffect(() => {
		if (
			firmanteNuevo.Nombres.trim() !== '' ||
			firmanteNuevo.Apellidos.trim() !== '' ||
			firmanteNuevo.Correo.trim() !== ''
		) {
			setCamposIncompletos(true);
		} else setCamposIncompletos(false);
	}, [firmanteNuevo, firmantes]);

	useEffect(() => {
		const fieldWithErrorIndex = errores.findIndex(obj => {
			return Object.values(obj).some(value => value !== '');
		});

		setDisabledFieldIndex(fieldWithErrorIndex);
	}, [errores]);
	
useEffect(() => {
	if (signers) {
		const firmantesConObservador = signers.map(firmante => ({
			...firmante,
			esObservador: firmante.esObservador || 0,
		}));
		setFirmantes(firmantesConObservador);
		setAddNewSigner(false);
	}
}, [signers]);

	/**
	 * Esta funcion genera un componente de icono utilizando el icono ArrowDropDownIcon y una clase proporcionada.
	 * @param {object} props Propiedades del componente, en este caso una clase de CSS personalizada.
	 * @returns {JSX.Element} Un componente de icono con la clase proporcionada.
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	const iconComponent = props => {
		return (
			<ArrowDropDownIcon className={props.className + ' ' + classes.icon} />
		);
	};

	/**
	 * Esta funcion crea una copia de firmantes y reorganiza el array cuando se hayan arrastrado los campos. 
	 * Reubica la lista de firmantes.
	 * @returns {Array} Retorna una nueva lista con las nuevas posiciones de los firmantes
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	const handleSort = () => {
		//duplicate items
		let _firmantes = [...firmantes];
		//remove and save the dragged item content
		const draggedItemContent = _firmantes.splice(dragItem.current, 1)[0];
		//switch the position
		_firmantes.splice(dragOverItem.current, 0, draggedItemContent);
		//reset the position ref
		dragItem.current = null;
		dragOverItem.current = null;
		//update the actual array
		const posicionesFirmantes = _firmantes.map((firmante, index) => {
			if (firmante.Posicion !== index + 1) {
				return { ...firmante, Posicion: index + 1 };
			}
			return firmante;
		});
		setFirmantes(posicionesFirmantes);
	};

	/**
	 * Esta funcion es la encarga de de deshabilitar el select de manuscrita cuando no se ha agregado nombre y apellido
	 * @returns {Boolean} Retorna true si los algun campo(nombre, apellido, correo) esta vacio, o false si los campos contienen algo
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	const disabledSelect = () => {
		if (
			firmanteNuevo.Nombres.trim().length === 0 ||
			firmanteNuevo.Apellidos.trim().length === 0 ||
			firmanteNuevo.Correo.trim().length === 0
		) {
			return true;
		} else {
			return false;
		}
	};

	/**
	 * Esta funcion crea una lista que contiene un objeto con la informacion del usuario activo en la sesion de FirmaYa,
	 * es la sugerencia que se hace cuando vamos a añadir un firmante al circuito
	 * @param {Array} user Contiene la informacion del usuario firmaYa actico en esa sesion.
	 * @returns {Array} Retorna un nuevo array donde cada elemento es un objeto con las propiedades: nombreCompleto, email, name, surname.
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	const createSuggestions = user => {
		return user.map(objeto => ({
			nombreCompleto: `${objeto.name} ${objeto.surname}`,
			email: objeto.email,
			name: objeto.name,
			surname: objeto.surname,
		}));
	};

	/**
	 * Esta funcion valida si el nombre ingresado cumple con los requisitos especificados, obligatorio, minimo 3 caracteres,
	 * sin caracteres especiales y sin numeros.
	 * @param {String} nombre El nombre a validar para que cumpla con los requisitos.
	 * @param {Boolean} isName isName es un booleano que valida si el mensaje a mostar debe llevar (nombre) si es true o (apellido) si es false
	 * @returns {String} Retorna un string en donde especifica la condicion que no se cumple.
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
		const validateNames = (nombre, isName) => {
			if (!nombre) {
				return isName ? 'El nombre es obligatorio' : 'El apellido es obligatorio';
			}
			if (nombre.length < 3) {
				return isName
					? 'El nombre debe tener al menos 3 caracteres'
					: 'El apellido debe tener al menos 3 caracteres';
			}
			if (/^[a-zA-ZáéíóúÁÉÍÓÚñÑ ]+$/.test(nombre)) {
				return '';
			}
			return isName
				? 'El nombre no puede contener números ni caracteres especiales'
				: 'El apellido no puede contener números ni caracteres especiales';
		};

	/**
	 * Esta funcion valida si el correo ingresado cumple con los requisitos especificados, obligatorio, 
	 * no puede estar repetido, debe cumplir con el formato de correo electronico (@)
	 * @param {String} correo El correo a validar para que cumpla con los requisitos
	 * @param {Number} index el index del firmante actual para la validacion del correo
	 * @returns {String} Retorna un string en donde especifica la condicion que no se cumple.
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	const mailValidator = (correo, index) => {
		const re = new RegExp(
			"^[\\w!#$%&'*+/=?`{|}~^-]+(?:\\.[\\w!#$%&'*+/=?`{|}~^-]+)*@(?:[a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,6}$"
		);
		const parts = correo.split('@');
		const [user, domain] = parts;
		const firmantesSinIndex = firmantes.filter((_fi, i) => i !== index);
		if (firmantesSinIndex.some(firmante => firmante.Correo === correo)) {
			return 'El correo ya existe';
		}
		if (!correo) {
			return 'El correo electronico es obligatorio';
		}
		if (parts.length !== 2) {
			return 'El correo electronico debe contener un @';
		}

		if (user.length > 64 || domain.length > 255) {
			return 'El correo electronico no es valido';
		}
		if (!re.test(correo)) {
			return 'El correo no tiene un formato valido';
		}
		return '';
	};

	/**
	 * Funcion que valida los campos a medida que se va escribiendo en ellos
	 * @param {String} campo Es el requerido para saber que validacion se hara (validar nombres o validar correo electronico)
	 * @param {String} valor Es el valor a validar
	 * @param {Number} index Es el index del firmante al cual se le esta haciendo la validacion
	 * @returns {String} Retorna un string en donde se especifica el error que se ha encontrado en la validacion en caso de que
	 * haya alguno, si no hay error devuelve un string vacio
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	const validateField = (campo, valor, index) => {
		switch (campo) {
			case 'Nombres':
				return validateNames(valor, true);
			case 'Apellidos':
				return validateNames(valor, false);
			case 'Correo':
				return mailValidator(valor, index);
			default:
				return '';
		}
	};
	
	/**
	 * Esta es la funcion que completa los campos y rellena los valores en el array de firmantes 
	 * cuando seleccionamos la sugerencia (El usuario activo ) para que haga parte del circuito
	 * @param {*} event es el evento que se ejecuta al realizar click
	 * @param {Object} newValue es un objeto con los valores del nuevo firmante a agregar en el circuito
	 * @returns {void} No retorna ningun valor pero actualiza los valores de firmanteNuevo y btnDisabled
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	const handleAutoCompleteChange = (event, newValue) => {
		if (newValue) {
			setFirmanteNuevo({
				...firmanteNuevo,
				Nombres: newValue.name,
				Apellidos: newValue.surname,
				Correo: newValue.email,
			});
			setBtnDisabled(false);
		} else {
			setFirmanteNuevo(initialNewSigner);
		}
	};

	/**
	 * Esta funcion reestablece los valores iniciales de la lista de firmanteNuevo para que los campos no muestren ningun error
	 * @returns {void}
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	const addnewSignerToList = () => {
		if (!addNewSigner) {
			setFirmanteNuevo(initialNewSigner);
			setAddNewSigner(true);
		}
	};

	/**
	 * Esta funcion maneja el evento de pegar texto en un campo de entrada.
	 * Quita los espacios en blanco que hayan al final (Se hace para prevenir que los usuarios peguen espacios en blanco seguidos)
	 * @param {Event} e evento de pegado (ctrl + v)
	 * @returns {void}
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	const handleOnpaste = e => {
		e.preventDefault();
		const clipboardData = e.clipboardData || window.clipboardData;
		const pastedText = clipboardData.getData('text/plain');
		if (pastedText.trim() !== '') {
			setFirmanteNuevo({
				...firmanteNuevo,
				Nombres: pastedText,
			});
		}
	};

		/**
	 * Valida la operacion para agregar o no los nuevos campos de agregar firmantes,
	 * verifica si los correos son validos y no estan repetidos.
	 * Valida que la lista de firmantes tenga llenos todos los campos y estos campos no tengan errores
	 * @returns {void}
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	const validateNewSigner = () => {
		if (!btnDisabled) {
			if (validateEmail(firmanteNuevo.Correo)) {
				if (
					firmantes?.some(firmante => firmante.Correo === firmanteNuevo.Correo)
				) {
					enqueueSnackbar(
						`Ya existe alguien con este correo en el circuito de firmas `,
						{
							style: { whiteSpace: 'pre-line' },
							variant: 'error',
							anchorOrigin: {
								vertical: 'top',
								horizontal: 'right',
							},
						}
					);
				} else {
					setFirmantes([...firmantes, firmanteNuevo]);
					setAddNewSigner(false);
					setFirmanteNuevo(initialNewSigner);
				}
			}
		} else {
			if (
				firmanteNuevo.Nombres.length < 1 &&
				firmanteNuevo.Apellidos.length < 1 &&
				firmanteNuevo.Correo < 1
			) {
				enqueueSnackbar(
					`Debes llenar los campos para poder solicitar otro firmante`,
					{
						style: { whiteSpace: 'pre-line' },
						variant: 'error',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
					}
				);
			} else if (firmanteNuevo.Nombres.length < 1) {
				enqueueSnackbar(`Debes llenar el campo nombre`, {
					style: { whiteSpace: 'pre-line' },
					variant: 'error',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
				});
			} else if (firmanteNuevo.Apellidos.length < 1) {
				enqueueSnackbar(`Debes llenar el campo apellidos`, {
					style: { whiteSpace: 'pre-line' },
					variant: 'error',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
				});
			}
		}
	};
const updateSigner = (index, campo, valor) => {
	const nuevosFirmantes = firmantes.map((firmante, i) => {
		if (i === index) {
			if (campo === 'esAprobador') {
				return {
					...firmante,
					esAprobador: valor ? 1 : 0,
					esObservador: 0,
				};
			} else if (campo === 'esObservador') {
				if (valor && firmante.firmaDocumentos.length > 0) {
					enqueueSnackbar(
						`Por favor, elimine los campos de firma de este firmante antes de cambiar a observador`,
						{
							variant: 'error',
							anchorOrigin: {
								vertical: 'top',
								horizontal: 'right',
							},
						}
					);
					return firmante; // No cambiar a observador
				} else {
					return {
						...firmante,
						esAprobador: 0,
						esObservador: valor ? 1 : 0,
					};
				}
			} else if (campo === 'Manuscrita') {
				return {
					...firmante,
					[campo]: valor,
					firmanteLibre: valor,
				};
			} else {
				return {
					...firmante,
					[campo]:
						typeof valor === 'string'
							? valor.replace(/\s+/g, ' ').trimStart()
							: valor,
				};
			}
		} else {
			return firmante;
		}
	});

	setFirmantes(nuevosFirmantes);


	const nuevosErrores = [...errores]; // Crear una copia del array de errores
	for (let i = 0; i <= index; i++) {
		// Recorrer los errores hasta el índice actual
		if (!nuevosErrores[i]) {
			// Si no hay un error en la posición actual, crear un objeto vacío
			nuevosErrores[i] = {};
		}
	}

	if (
		campo !== 'Manuscrita' &&
		campo !== 'firmanteLibre' &&
		campo !== 'esAprobador' &&
		campo !== 'esObservador'
	) {
		const error = validateField(
			campo,
			typeof valor === 'string'
				? valor.replace(/\s+/g, ' ').trimStart()
				: valor,
			index
		);

		nuevosErrores[index] = {
			...nuevosErrores[index],
			[campo]: error,
		};

		if (campo === 'Correo' && error === '') {
			const firmantesConMismoCorreo = nuevosFirmantes.filter(
				firmante => firmante.Correo === valor
			);
			if (firmantesConMismoCorreo.length === 1) {
				const firmanteConPrevValue = firmantes.find(
					firmante => firmante.Correo === valor
				);
				if (firmanteConPrevValue) {
					const indexFirmanteConPrevValue = nuevosFirmantes.findIndex(
						firmante => firmante.Correo === valor
					);

					nuevosErrores[indexFirmanteConPrevValue] = {
						...nuevosErrores[indexFirmanteConPrevValue],
						[campo]: '',
					};
				}
			}
		}
		setErrores(nuevosErrores);
	}

	const isError = nuevosErrores.some(error => {
		// verificar si hay algún error en el nuevo array de errores
		return (
			(error.Nombres !== undefined && error.Nombres.trim() !== '') ||
			(error.Apellidos !== undefined && error.Apellidos.trim() !== '') ||
			(error.Correo !== undefined && error.Correo.trim() !== '')
		);
	});
	if (isError) {
		setErrorExist(true);
	} else {
		setErrorExist(false);
	}
};

	/**
	 * Elimina un firmante de la lista de firmantes.
	 * @param {Number} index - El índice del firmante que se eliminará.
	 * @param {Event} event - El evento que desencadenó la eliminación.
	 * @returns {void}
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	const deleteSigner = (index, event) => {
		event.preventDefault();
		const nuevosErrores = errores.filter((error, i) => {
			return i !== index;
		});

		// Agregar objetos vacíos a las posiciones anteriores que no tienen errores existentes
		for (let i = nuevosErrores.length; i < firmantes.length; i++) {
			nuevosErrores[i] = {};
		}

		setErrores(nuevosErrores);

		const nuevosFirmantesXD = [...firmantes];
		nuevosFirmantesXD.splice(index, 1);
		setFirmantes(nuevosFirmantesXD);

		// Verificar si aún hay errores después de eliminar la fila
		const isError = nuevosErrores.some(error => {
			return (
				(error.Nombres !== undefined && error.Nombres.trim() !== '') ||
				(error.Apellidos !== undefined && error.Apellidos.trim() !== '') ||
				(error.Correo !== undefined && error.Correo.trim() !== '')
			);
		});
		setErrorExist(isError); // Actualizar la variable errorExist según si aún hay errores
		if (
			textValidationCorreo === 'Este email ya pertenece al circuito de firmas'
		) {
			validateEmailCFDelete(nuevosFirmantesXD, firmantes, index);
		}
	};

	/**
	 * Valida el correo electrónico del nuevo firmante al eliminar un firmante existente.
	 * Si el correo coincide con el nuevo firmante, actualiza el estado de la validación y habilita el botón si se cumplen ciertas condiciones.
	 * @param {Array} nuevosFirmantes - La lista actualizada de firmantes después de eliminar un firmante existente.
	 * @param {Array} firmantes - La lista original de firmantes.
	 * @param {number} index - El índice del firmante que se eliminó.
	 * @returns {void}
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	const validateEmailCFDelete = (nuevosFirmantes, firmantes, index) => {
		const firm = firmantes.find((firmant, i) => i === index);

		if (firm.Correo === firmanteNuevo.Correo) {
			setTextValidationCorreo('');
			setErrorValidationCorreo(false);
			setBtnDisabledCorreo(false);
			if (
				firmanteNuevo.Nombres.length >= 3 &&
				firmanteNuevo.Apellidos.length >= 3 &&
				validateEmail(firmanteNuevo.Correo)
			) {
				setFirmantes([...nuevosFirmantes, firmanteNuevo]);
				setAddNewSigner(false);
				setFirmanteNuevo(initialNewSigner);
			}
		}
	};

	/**
	 * Edita el nombre de la carpeta actual.
	 * Si la longitud del nuevo nombre es menor que 5 caracteres, se restaura el nombre anterior y se ajusta la medida.
	 * Si la longitud del nuevo nombre es al menos 5 caracteres, actualiza el nombre de la carpeta en el estado global,
	 * @returns {void}
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	const editFolderName = () => {
		if (folderName.length < 5) {
			setFolderName(folder);
			setMedida(folder.length * 10);
		} else {
			dispatch(name_folder(folderName.replace(/\s+/g, ' ').trim()));
			setFolderName(folderName.replace(/\s+/g, ' ').trim());
			setMedida(folderName.replace(/\s+/g, ' ').trim().length * 10);
		}
		setEditar(false);
	};

	/**
	 * Actualiza el nombre de la carpeta según el valor proporcionado en el evento.
	 * Elimina los espacios en blanco al principio y al final del valor
	 * @param {Event} e  El evento que contiene el nuevo valor del nombre de la carpeta.
	 * @returns {void}
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	const changeNameFolder = e => {
		setFolderName(e.target.value.replace(/\s+/g, ' ').trimStart());
		setMedida(e.target.value.replace(/\s+/g, ' ').trimStart().length * 10);
	};

	/**
	 * Maneja el evento de pulsación de tecla para evitar que se ingresen espacios seguidos.
	 * @param {*} e El evento de teclado
	 * @returns {void}
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	const handleKeyPress = e => {
		if (e.key === ' ' && e.target.value.endsWith(' ')) {
			e.preventDefault();
		}
	};

	/**
	 * Agrega un nuevo firmante si cumple con los requisitos de longitud mínima para nombres y apellidos,
	 * y si la dirección de correo electrónico es válida.
	 * @returns {void}
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	const addSigner = () => {
		if (
			firmanteNuevo.Nombres.length >= 3 &&
			firmanteNuevo.Apellidos.length >= 3 &&
			validateEmail(firmanteNuevo.Correo)
		) {
			validateNewSigner();
		}
	};

	/**
	 * Avanza a la siguiente vista, actualizando la posición de los firmantes si existen firmantes,
	 * y realizando las acciones correspondientes, como eliminar campos de firma si se ha modificado la opcion a sin manuscrita.
	 * Si no hay firmantes, muestra una notificación de error.
	 * @returns {void}
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	const nextStep = () => {
		if (firmantes.length > 0) {
			        const existeFirmanteNoObservador = firmantes.some(
								firmante => firmante.esObservador === 0
							);
							if (!existeFirmanteNoObservador) {
								enqueueSnackbar(
									'Debe haber al menos un firmante que no sea observador en el circuito.',
									{
										style: { whiteSpace: 'pre-line' },
										variant: 'error',
										anchorOrigin: {
											vertical: 'top',
											horizontal: 'right',
										},
									}
								);
								return;
							}
			const posicionesFirmantes = firmantes.map((firmante, index) => {
				let firmanteLibre = true;
				if (firmante.Manuscrita) {
					if (firmante.firmaDocumentos && firmante.firmaDocumentos.length > 0) {
						firmanteLibre = false;
					}
				} else {
					firmanteLibre = false;
					firmante.firmaDocumentos = [];
				}
				return { 
					...firmante, 
					Posicion: index + 1, 
					firmanteLibre 
				};
			});
			let idsCamposActuales = [];
			posicionesFirmantes.forEach(firmante => {
				firmante.firmaDocumentos.forEach(documento => {
					idsCamposActuales.push(documento.PosicionFirma.id);
				});
			});
			const camposFiltrados = camposAnteriores.filter(campo => idsCamposActuales.includes(campo.idReSign) || campo.source === 'qr');
			dispatch(camposHechos(camposFiltrados));
			setFirmantes(posicionesFirmantes);
			dispatch(stepCircuit(2));
			dispatch(firm_positions(posicionesFirmantes));
		} else {
			enqueueSnackbar(`Debes tener al menos un firmante `, {
				style: { whiteSpace: 'pre-line' },
				variant: 'error',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'right',
				},
			});
		}
	};

	/**
	 * Determina si un campo específico debe estar deshabilitado basándose en ciertos criterios, como el índice del campo,
	 * y si hay errores de validación en el apellido, nombre o correo electrónico.
	 * @param {number} index El índice del campo.
	 * @returns {boolean} True si el campo debe estar deshabilitado, de lo contrario false.
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	const disabledFields = index =>
		(disabledFieldIndex !== -1 && index !== disabledFieldIndex) ||
		errorValidationApellido ||
		errorValidationNombre ||
		errorValidationCorreo;

	return (
		<Grid container spacing={1} direction='row' id='modal-pdf'>
			<Container component='main' maxWidth='lg'>
				<h1 className={'title-background'}>Firmar documentos</h1>
			</Container>
			<Container component='main' maxWidth='lg'>
				<Grid
					container
					spacing={0}
					direction='row'
					justify='flex-start'
					alignItems='flex-start'
					className='zone-upload-container'
					maxWidth='md'
				>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<Paper elevation={0} className={'paper2-upload'}>
							<CustomizedSteppers activeStep={1} />
							<h2
								className={classes.parrafo}
								style={{
									color: '#E55200',
									fontSize: '23px',
									margin: '3px 0 0 0 ',
								}}
							>
								{' '}
								Agregar firmantes{' '}
							</h2>
							<p className={classes.size}>
								{' '}
								Incluye los firmantes que quieres que hagan parte de tu circuito
								de firmas.
							</p>
							{documentos.length > 1 ? (
								<>
									<div className={classes.nameDocuments}>
										<img
											src={folders}
											style={{ width: '18px', height: '16px' }}
											alt='folder'
										/>
										{editar ? (
											<input
												value={folderName}
												style={{ width: `${medida}px` }}
												onChange={changeNameFolder}
												onBlur={() => editFolderName()}
												minLength={5}
												onKeyDown={e => preventSpaceWhenEmpty(folderName, e)}
												maxLength={50}
												ref={inputRef}
											/>
										) : (
											<input
												value={folderName}
												style={{ width: `${medida}px` }}
												disabled
											/>
										)}
										<img
											onClick={() => setEditar(!editar)}
											src={lapiz}
											style={{
												width: '15px',
												height: '15px',
												cursor: 'pointer',
											}}
										/>
									</div>
									<ul style={{ margin: '0px 0px 30px' }}>
										{documentos.map(documento => (
											<li
												key={documento.id}
												className='listDocuments'
												style={{ height: '20px' }}
											>
												{documento?.path || documento?.name}
											</li>
										))}
									</ul>
								</>
							) : (
								<>
									<small style={{ fontSize: '12px', color: '#4F4F4F' }}>
										Archivo Adjunto:{' '}
									</small>
									<p
										className={classes.parrafo}
										style={{
											fontWeight: '600',
											fontSize: '16px',
											color: '#4F4F4F',
											marginBottom: '25px',
										}}
									>
										{documentos[0]?.path || documentos[0]?.name}
									</p>
								</>
							)}{' '}
							<Box overflow='auto'>
								{firmantes.length >= 1 &&
									firmantes?.map((firmante, index) => (
										<>
											<div
												className={classes.containerFirmantes}
												draggable={errorExist ? false : true}
												key={firmante}
												onDragStart={
													errorExist ? null : e => (dragItem.current = index)
												}
												onDragEnter={
													errorExist
														? null
														: e => (dragOverItem.current = index)
												}
												onDragEnd={errorExist ? null : handleSort}
												onDragOver={errorExist ? null : e => e.preventDefault()}
											>
												<div className={classes.square}>
													<span>{index + 1}</span>
												</div>

												<TextField
													InputProps={{
														disableUnderline: true,
														whiteSpace: 'nowrap',
													}}
													inputProps={{
														style: {
															whiteSpace: 'nowrap',
															fontSize: '14px',
															fontFamily: 'Mulish',
															background: 'white',
															border: '1px solid #706F6F',
															borderRadius: '10px',
															boxShadow: '0 1px 3px 0 rgba(0,0,0,0.46)',
															maxHeight: '6px',
														},
													}}
													InputLabelProps={{
														style: { fontSize: '14px', whiteSpace: 'nowrap' },
													}}
													className={classes.textField}
													label='Nombres'
													name='Nombres'
													value={firmante.Nombres}
													fullWidth
													onKeyPress={handleKeyPress}
													size='small'
													variant='filled'
													onChange={event => {
														updateSigner(index, 'Nombres', event.target.value);
													}}
													onKeyDown={e =>
														preventSpaceWhenEmpty(firmante.Nombres, e)
													}
													disabled={disabledFields(index)}
													error={
														errores
															? errores[index]
																? errores[index].Nombres
																	? errores[index].Nombres !== ''
																		? true
																		: false
																	: false
																: false
															: false
													}
													helperText={
														errores
															? errores[index]
																? errores[index].Nombres
																	? errores[index].Nombres !== ''
																		? errores[index].Nombres
																		: ''
																	: ''
																: ''
															: ''
													}
												/>
												<TextField
													InputProps={{ disableUnderline: true }}
													inputProps={{
														style: {
															fontSize: '14px',
															fontFamily: 'Mulish',
															backgroundColor: 'white',
															border: '1px solid #706F6F',
															borderRadius: '10px',
															boxShadow: '0 1px 3px 0 rgba(0,0,0,0.46)',
															maxHeight: '6px',
														},
													}}
													InputLabelProps={{ style: { fontSize: '14px' } }}
													className={classes.textField}
													label='Apellidos'
													name='Apellidos'
													onKeyPress={handleKeyPress}
													fullWidth
													variant='filled'
													size='small'
													value={firmante.Apellidos}
													onChange={event =>
														updateSigner(index, 'Apellidos', event.target.value)
													}
													onKeyDown={e =>
														preventSpaceWhenEmpty(firmante.Apellidos, e)
													}
													disabled={disabledFields(index)}
													error={
														errores
															? errores[index]
																? errores[index].Apellidos
																	? errores[index].Apellidos !== ''
																		? true
																		: false
																	: false
																: false
															: false
													}
													helperText={
														errores
															? errores[index]
																? errores[index].Apellidos
																	? errores[index].Apellidos !== ''
																		? errores[index].Apellidos
																		: ''
																	: ''
																: ''
															: ''
													}
												/>
												<TextField
													InputProps={{ disableUnderline: true }}
													inputProps={{
														style: {
															fontSize: '14px',
															fontFamily: 'Mulish',
															backgroundColor: 'white',
															border: '1px solid #706F6F',
															borderRadius: '10px',
															boxShadow: '0 1px 3px 0 rgba(0,0,0,0.46)',
															maxHeight: '6px',
														},
													}}
													InputLabelProps={{
														style: { fontSize: '14px', fontFamily: 'Mulish' },
													}}
													className={classes.textField}
													label='Correo'
													name='Correo'
													fullWidth
													size='small'
													variant='filled'
													value={firmante.Correo}
													onKeyPress={handleKeyPress}
													onKeyDown={e =>
														preventSpaceWhenEmpty(firmante.Correo, e)
													}
													onChange={event => {
														updateSigner(index, 'Correo', event.target.value);
													}}
													disabled={disabledFields(index)}
													error={
														errores
															? errores[index]
																? errores[index].Correo
																	? errores[index].Correo !== ''
																		? true
																		: false
																	: false
																: false
															: false
													}
													helperText={
														errores
															? errores[index]
																? errores[index].Correo
																	? errores[index].Correo !== ''
																		? errores[index].Correo
																		: ''
																	: ''
																: ''
															: ''
													}
												/>
												<Select
													displayEmpty
													disableUnderline
													value={firmante.Manuscrita}
													classes={{ root: classes.select }}
													MenuProps={menuProps}
													IconComponent={iconComponent}
													disabled={disabledFields(index)}
													onChange={event => {
														updateSigner(
															index,
															'Manuscrita',
															event.target.value
														);
													}}
												>
													<MenuItem value={false}>
														Sin firma manuscrita
													</MenuItem>
													<MenuItem value={true}>Con firma manuscrita</MenuItem>
												</Select>

												<Select
													value={
														firmante.esAprobador
															? 'Aprobador'
															: firmante.esObservador
															? 'Observador'
															: 'Rol'
													}
													onChange={event => {
														const valor = event.target.value;
														if (valor === 'Aprobador') {
															updateSigner(index, 'esAprobador', 1);
														} else if (valor === 'Observador') {
															updateSigner(index, 'esObservador', 1);
														} else {
															updateSigner(index, 'esAprobador', 0);
															updateSigner(index, 'esObservador', 0);
														}
													}}
													displayEmpty
													disableUnderline
													classes={{ root: classes.select2 }}
													MenuProps={menuProps}
													IconComponent={iconComponent}
													disabled={disabledFields(index)}
												>
													<MenuItem value='Rol'>
														<Tooltip title='Ninguno' placement='top'>
															<em>Sin Rol</em>
														</Tooltip>
													</MenuItem>
													{/* <MenuItem value='sin rol'>
														<Tooltip title='Ninguno' placement='top'>
															<span>sin rol</span>
														</Tooltip>
													</MenuItem> */}
													<MenuItem value='Aprobador'>
														<Tooltip title='Aprobador' placement='top'>
															<CheckCircleIcon style={{ marginRight: 8 }} />
														</Tooltip>
													</MenuItem>
													<MenuItem value='Observador'>
														<Tooltip title='Observador' placement='top'>
															<VisibilityIcon style={{ marginRight: 8 }} />
														</Tooltip>
													</MenuItem>
												</Select>

												<button
													onClick={event => deleteSigner(index, event)}
													className={classes.btnDel}
												>
													<img src={del} alt='Delete' />
												</button>
											</div>
										</>
									))}
								{addNewSigner && (
									<div className={classes.containerFirmantes} draggable>
										<div className={classes.square}>
											{firmantes ? (
												<span>{firmantes.length + 1}</span>
											) : (
												<span>1</span>
											)}
										</div>
										<Autocomplete
											onBlur={addSigner}
											size='small'
											value={firmanteNuevo.Nombres}
											freeSolo
											classes={{
												inputRoot: classes.inputRoots,
												root: classes.autocompleteRoot,
												option: classes.opt,
												paper: classes.paper2,
											}}
											selectOnFocus
											clearOnBlur
											disableClearable
											handleHomeEndKeys
											renderOption={option => (
												<Grid
													container
													alignItems='center'
													justifyContent='start'
													direction='row'
													style={{
														gap: '8px',
													}}
												>
													<Grid item xs={1}>
														<img src={avatar} alt='avatar' />
													</Grid>
													<Grid item xs={10}>
														<Grid container>
															<Grid item xs={12}>
																<span className={classes.suggestionName}>
																	{truncateText(
																		`${capitalize(option.name)} ${capitalize(
																			option.surname
																		)}`,
																		24
																	)}
																</span>
															</Grid>
															<Grid item xs={12}>
																<span className={classes.suggestionEmail}>
																	{truncateText(option.email, 24)}
																</span>
															</Grid>
														</Grid>
													</Grid>
												</Grid>
											)}
											options={createSuggestions([userInfo])}
											getOptionLabel={option => {
												if (typeof option === 'string') {
													return option;
												}
												if (option.inputValue) {
													return option.inputValue;
												}

												return option.name;
											}}
											onChange={handleAutoCompleteChange}
											filterOptions={(options, params) => {
												const paramsEmpty = {
													...params,
													inputValue: '',
												};
												const filtered = filter(options, paramsEmpty);
												// Suggest the creation of a new value
												return filtered;
											}}
											renderInput={params => (
												<TextField
													{...params}
													className={classes.textFields}
													size='small'
													InputProps={{
														...params.InputProps,
														disableUnderline: true,
													}}
													error={errorValidationNombre}
													helperText={textValidationNombre}
													label='Nombres'
													onKeyPress={handleKeyPress}
													name='Nombres'
													value={firmanteNuevo.Nombres}
													fullWidth
													variant='filled'
													onKeyDown={e =>
														preventSpaceWhenEmpty(firmanteNuevo.Nombres, e)
													}
													onPaste={handleOnpaste}
													onChange={event => {
														setFirmanteNuevo({
															...firmanteNuevo,
															Nombres: event.target.value.trim(),
														});
													}}
												/>
											)}
										/>
										<TextField
											onBlur={addSigner}
											InputProps={{ disableUnderline: true }}
											inputProps={{
												style: {
													fontSize: '14px',
													fontFamily: 'Mulish',
													backgroundColor: 'white',
													border: '1px solid #706F6F',
													borderRadius: '10px',
													boxShadow: '0 1px 3px 0 rgba(0,0,0,0.46)',
													maxHeight: '6px',
												},
											}}
											InputLabelProps={{ style: { fontSize: '14px' } }}
											className={classes.textField}
											onKeyPress={handleKeyPress}
											error={errorValidationApellido}
											helperText={textValidationApellido}
											label='Apellidos'
											name='Apellidos'
											value={firmanteNuevo.Apellidos}
											onKeyDown={e =>
												preventSpaceWhenEmpty(firmanteNuevo.Apellidos, e)
											}
											fullWidth
											variant='filled'
											size='small'
											onChange={event =>
												setFirmanteNuevo({
													...firmanteNuevo,
													Apellidos: event.target.value
														.replace(/\s+/g, ' ')
														.trimStart(),
												})
											}
										/>
										<TextField
											onBlur={addSigner}
											InputProps={{ disableUnderline: true }}
											inputProps={{
												style: {
													fontSize: '14px',
													fontFamily: 'Mulish',
													backgroundColor: 'white',
													border: '1px solid #706F6F',
													borderRadius: '10px',
													boxShadow: '0 1px 3px 0 rgba(0,0,0,0.46)',
													maxHeight: '6px',
												},
											}}
											InputLabelProps={{ style: { fontSize: '14px' } }}
											className={classes.textField}
											error={errorValidationCorreo}
											helperText={textValidationCorreo}
											onKeyPress={handleKeyPress}
											label='Correo'
											name='Correo'
											value={firmanteNuevo.Correo}
											onKeyDown={e =>
												preventSpaceWhenEmpty(firmanteNuevo.Correo, e)
											}
											autoComplete='off'
											fullWidth
											size='small'
											variant='filled'
											onChange={event => {
												setFirmanteNuevo({
													...firmanteNuevo,
													Correo: event.target.value
														.replace(/\s+/g, ' ')
														.trimStart(),
												});
											}}
										/>
										<Select
											disableUnderline
											disabled={disabledSelect()}
											value={firmanteNuevo.Manuscrita}
											classes={{ root: classes.select }}
											MenuProps={menuProps}
											IconComponent={iconComponent}
											onChange={event =>
												setFirmanteNuevo({
													...firmanteNuevo,
													Manuscrita: event.target.value,
												})
											}
										>
											<MenuItem value={false}>Sin firma manuscrita</MenuItem>
											<MenuItem value={true}>Con firma manuscrita</MenuItem>
										</Select>
										<Select
											value={
												firmanteNuevo.esAprobador
													? 'Aprobador'
													: firmanteNuevo.esObservador
													? 'Observador'
													: 'Rol'
											}
											onChange={event => {
												const valor = event.target.value;
												if (valor === 'Aprobador') {
													setFirmanteNuevo({
														...firmanteNuevo,
														esAprobador: 1,
														esObservador: 0,
													});
												} else if (valor === 'Observador') {
													setFirmanteNuevo({
														...firmanteNuevo,
														esAprobador: 0,
														esObservador: 1,
													});
												} else {
													setFirmanteNuevo({
														...firmanteNuevo,
														esAprobador: 0,
														esObservador: 0,
													});
												}
											}}
											displayEmpty
											disableUnderline
											classes={{ root: classes.select2 }}
											MenuProps={menuProps}
											IconComponent={iconComponent}
											disabled={disabledSelect()}
										>
											<MenuItem value='Rol'>
												<Tooltip title='Ninguno' placement='top'>
													<em>Sin Rol</em>
												</Tooltip>
											</MenuItem>
											{/* <MenuItem value='sin rol'>
												<Tooltip title='Ninguno' placement='top'>
													<span>sin rol</span>
												</Tooltip>
											</MenuItem> */}
											<MenuItem value='Aprobador'>
												<Tooltip title='Aprobador' placement='top'>
													<CheckCircleIcon style={{ marginRight: 8 }} />
												</Tooltip>
											</MenuItem>
											<MenuItem value='Observador'>
												<Tooltip title='Observador' placement='top'>
													<VisibilityIcon style={{ marginRight: 8 }} />
												</Tooltip>
											</MenuItem>
										</Select>

										<button
											className={classes.btnDel}
											onClick={() => {
												if (firmantes.length === 0) {
													setFirmanteNuevo(initialNewSigner);
												} else {
													setFirmanteNuevo(initialNewSigner);
													setAddNewSigner(false);
												}
											}}
										>
											<img src={del} alt='eliminar' />
										</button>
									</div>
								)}
							</Box>
							<Box className={classes.containerBtns}>
								<Button
									onClick={() => addnewSignerToList()}
									className={classes.add}
									disabled={
										(btnDisabled && camposIncompletos) ||
										errorExist ||
										addNewSigner
									}
									// disabled={(btnDisabled && camposIncompletos) || errorExist }
								>
									<img src={add} alt='agregar' />
									<span style={{ marginLeft: '10px' }}>
										<b>Agregar firmante</b>
									</span>
								</Button>
								<Button
									onClick={nextStep}
									className={classes.continue}
									disabled={(btnDisabled && camposIncompletos) || errorExist}
								>
									Continuar
									<ArrowForwardIosIcon
										style={{ marginLeft: '10px', width: '15px' }}
									/>
								</Button>
							</Box>
						</Paper>
					</Grid>
				</Grid>
			</Container>
		</Grid>
	);
};

export default AddSignersCF;
