import React, { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import { useSnackbar } from 'notistack';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import { getCerificatesByUser } from '../../redux/actions/EncryptDecryptAction';
import Loading from '../../components/Loading';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import noRecipient from '../../assets/img/noRecipient.png'
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { GET_CERTIFICATES } from '../../redux/types/EncryptDecryptTypes';
import { MAX_RECIPIENT_ENCRYPT } from '../../redux/types/ApiConstants';
import { useDispatch } from "react-redux";
import { Checkbox } from '@material-ui/core';
 
const useStyles = makeStyles((theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
	dropzoneTextStyle: {
		flex: 1,
		paddingBottom: '38px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		fontSize: '20px',
	},
    titleRecipient: {
        font: 'normal normal bold 20px/20px Muli',
        margin: '0 0 0 0',
        color: '#E55200',
        fontSize:'20px',
        paddingBottom: '42px',
        textAlign:'center',
        paddingTop: '5px',
    },
	titleTable: {
		font: 'normal normal bold 14px/16px Muli',
		letterSpacing: '0px',
		color: '#E55200',
		opacity: '1',
	},
    tableContainer: {
		marginTop: 9,
        margin: '0 0 0 42px',
        height:'180px',
		width:'500px',
		marginBottom: 24,
	},
    subTitle: {
        font: 'normal normal normal 14px/18px Muli',
        margin: '0 0 0 42px',
        color: '#4F4F4F',
        paddingTop: '30px',
    },
    subTitle6: {
        font: 'normal normal bold 14px/18px Muli',
        textTransform: "capitalize !important",
		color: '#E55200',
    },
    subTitle5: {
        font: 'normal normal normal 16px/24px Muli',
        color: '#4F4F4F',
        paddingTop: '43px',
    },
    subTitle2: {
        font: 'normal normal normal 12px/17px Muli',
        margin: '0 0 0 42px',
        color: '#056C8D',
        paddingTop: '30px',
    },
	tableCell:{
		paddingTop:'7px',
		paddingBottom:'7px'
	},
	resize:{
        paddingLeft: '4%',
        paddingTop: '7px',
        font: 'normal normal 600 16px/22px Muli',
        letterSpacing: '0px',
        color: '#00000080',
        opacity: '1',
	},
}));

const useStylesCheckOrange = makeStyles({
	root: {
		"&:hover": {
			backgroundColor: "transparent"
		}
	},
	icon: {
		borderRadius: 3,
		width: 14,
		height: 14,
		boxShadow:
			"inset 0 0 0 1px rgba(157, 157, 156,1.0)",
		backgroundColor: "white",
		backgroundImage:
			"linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
		"$root.Mui-focusVisible &": {
			outline: "2px auto rgba(19,124,189,.6)",
			outlineOffset: 2
		},
	},
	checkedIcon: {
		backgroundColor: "white",
		backgroundImage:
			"linear-gradient(10deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
		"&:before": {
			display: "block",
			width: 14,
			height: 14,
			backgroundImage:
				"url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
				" fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
				"1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='rgb(229, 82, 0)'/%3E%3C/svg%3E\")",
			content: '""'
		},
		"input:hover ~ &": {
			backgroundColor: "white"
		}
	}
});

function StyledCheckbox(props) {
	const classes = useStylesCheckOrange();
	return (
		<Checkbox
			className={classes.root}
			disableRipple
			checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
			icon={<span className={classes.icon} />}
			inputProps={{ "aria-label": "decorative checkbox" }}
			{...props}
		/>
	);
}
const ModalRecipient = (props) => {
	const {
		certificates, setStatusModalRecipient, selectedCerts, setSelectedCerts, getCertificates
	} = props;
	const { enqueueSnackbar } = useSnackbar();
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const [correctEmail, setCorrectEmail] = useState(false);
	const [correctId, setCorrectId] = useState(false);
	const [notFound, setNotFound] = useState(false);
	const [selected, setSelected] = useState([]);
    const dispatch = useDispatch();
	const ref = useRef(null);

	useEffect(() => {
		const fetchData = async () => {
			setLoading(false);
		};
		fetchData();
		dispatch({
			type: GET_CERTIFICATES,
			payload: "",
		});
	}, []);

	const handleChange = (event, name) => {
		var repeat = false;
		if(selected.length >= MAX_RECIPIENT_ENCRYPT || selectedCerts.length >= MAX_RECIPIENT_ENCRYPT){
			enqueueSnackbar(`Se permiten máximo ${MAX_RECIPIENT_ENCRYPT} destinatarios`, {
				variant: 'error',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'right',
				},
			});
			return;
		}
		else if(!event.target.checked) setSelected(selected.filter((x) => x !== name));
		else {
			selectedCerts.map(newe => {
				if (newe.uuid == certificates[name].uuid) {
					enqueueSnackbar(`El certificado ya esta agregado.`, {
						variant: 'error',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
					});
					repeat=true;
					return;
				}
			})
			if(!repeat) setSelected([...selected, name]);
		};
	};

	const handleRecipients = async () => {
		let newSelected = selectedCerts;
		await Promise.all(selected.map(newe => {newSelected.push(certificates[newe])}))
		setSelectedCerts([...newSelected]);
		setStatusModalRecipient(false);
	};
	
	const isSelected = (name) => selected.indexOf(name) !== -1;
	
	const searchUsers = async () => {
		let newData = new Object();
		if(correctEmail) {
			newData.email = ref.current.value;
			newData.document = null;
		} else {
			newData.email = null;
			newData.document = ref.current.value;
		}
		setLoading(true);
		await getCertificates(enqueueSnackbar, newData, setNotFound);
		setLoading(false);
	}

	const verifyCertificates = async () => {
		if (/^(?!.*?\.\.)([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/.test(ref.current.value)){
			setCorrectId(false);
			setCorrectEmail(true);
	   	} else if(/^([A-Za-z0-9]){5,}$/.test(ref.current.value)){
			setCorrectEmail(false);
			setCorrectId(true);
	   	} else {
			setCorrectId(false);
			setCorrectEmail(false);
		}
	}

	return (
		<>
		{loading && <Loading />}
		<Paper elevation={3} className="paperRecipient">
			<Grid
				container
				direction="row"
				className={classes.grid}
				justify="flex-end"
			>
				<IconButton
					edge="start"
					id="btn-recipient-close"
					aria-label="upload file"
					onClick={() => setStatusModalRecipient(false)}
					className={'btn-close-sign-modal'}
				>
				</IconButton>
			</Grid>
            <h1 className={classes.titleRecipient}>Agregar destinatario</h1>
            <Grid
                style={{ paddingLeft: '9px', width: '100%' }}
				container
                spacing={1}
				direction="row"
				justify="center"
			>   
                <Grid item>
                    <TextField
			        	className={'recipientSearch'}
						onChange={verifyCertificates}
						inputRef={ref}
                        placeholder="No. de documento o email del destinatario"
                        inputProps={{ maxLength: 50 }}
						InputProps={ { classes: { input: classes.resize } } }
			        />
                </Grid>
                <Grid item>
                    <Button
                        className={'buttonSearchRecipient'}
						disabled={!correctEmail && !correctId}
						onClick={searchUsers}
                        endIcon={<SearchIcon style={{ marginLeft: '-11px', fontSize: 25 }}/>}
                    >
                    </Button>
                </Grid>
            </Grid>
			{notFound &&
			<center>
				<h1 className={classes.subTitle5}>¡No se encontraron coincidencias!</h1>
				<img style={{ paddingBottom: '50px', paddingTop: '9px' }} src={noRecipient} className={classes.logo}/>
			</center>}
            {certificates.length > 0 && <>
			<h1 className={classes.subTitle}>Lista certificados de <span className={classes.subTitle6}>{certificates[0].cn}</span></h1>
            <h1 className={classes.subTitle2}>Los siguientes certificados son emitidos por GSE, los que selecciones en esta lista, serán <br/> los mismos que se utilicen para descifrar el archivo.</h1>
            <TableContainer className={classes.tableContainer}>
				<Table>
					<TableHead id="tableUsers" className={'headerTableDocumentSigned'}>
						<TableRow>
							<TableCell></TableCell>
							<TableCell><Typography style={{ marginLeft:'-40px' }} className={classes.titleTable}>Identificador</Typography></TableCell>
							<TableCell><Typography style={{ marginLeft:'119px' }} className={classes.titleTable}>Tipo de certificado</Typography></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{certificates.map((item, index) => {
							const isItemSelected = isSelected(index);				
							return (
							<TableRow
								onClick={(event) => handleChange(event, index)}
								role="checkbox"
								key={index}>
								<TableCell className={classes.tableCell} align="left">
									<StyledCheckbox
										checked={isItemSelected}
									/>
								</TableCell>
								<TableCell className={classes.tableCell}><div style={{ marginLeft: '-50px' }}>{item.userId}</div></TableCell>
								<TableCell className={classes.tableCell}><div style={{ marginLeft: '20px' }}>{item.tipo}</div></TableCell>
							</TableRow>
							)}
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<div className={classes.dropzoneTextStyle}>
				<Button
					disableElevation
					onClick={handleRecipients}
					disabled={selected.length < 1}
					id="buttonRecipientBlock"
					className={'buttonRecipient'}
					endIcon={<AddIcon style={{ paddingTop: '4px', marginLeft: '-5px', fontSize: 27 }} />}
				>
					Agregar
                </Button>
			</div>
			</>}
		</Paper>
		</>
	);
};

const mapStateToProps = (state) => ({
	certificates: state.encryptDecrypt.certificates
});

const mapDispatchToProps = {
	getCertificates: getCerificatesByUser
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModalRecipient));