import { makeStyles, Modal } from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import rechazado from "../../../../assets/img/firmaRechazada.png";
import { modalSignatureReject } from "../../../../redux/actions/CircuitoFirmasAction";
import { LANDING_PAGE_FIRMAYA } from "../../../../redux/types/ApiConstants";

const useStyle = makeStyles({
  modal: {
    margin: 0,
    padding: "30px",
    position: "absolute",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0px 3px 0px #00000033 !important",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "5.72px",
    width: "442px",
    height: "357px",
    "&:focus": {
      outline: "none",
    },
  },
  title: {
    color: "#E55200",
    fontSize: "24px",
    fontFamily: "Mulish",
    fontWeight: "700",
    margin: "20px 0 0 0",
    lineHeight: "30px",
  },
  parrafo: {
    fontSize: "16px",
    width: "475px",
    fontFamily: "Mulish",
    fontWeight: "400",
  },
  flex: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "16px",
    marginTop: "40px",
  },
  parrafo: {
    fontSize: "16px",
    textAlign: "center",
    margin: "24px 0 0 0",
  },
  btnCerrar: {
    color: "#E55200",
    padding: "8px 24px",
    fontSize: "16px",
    cursor: "pointer",
    border: "1px solid #E55200",
    borderRadius: "8px",
    backgroundColor: "transparent",
    fontFamily: "Mulish",
    fontWeight: "700",
    margin: 0,
  },
  continue: {
    backgroundColor: "#E55200",
    color: "white",
    padding: "8px 24px",
    fontSize: "16px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    fontWeight: "800",
    fontFamily: "Mulish",
    border: "1px solid #E55200",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: "#E55200",
    },
  },
});

const ModalSignatureRejected = ({ irhome = false }) => {
  const dispatch = useDispatch();
  const classes = useStyle();
  const [modal, setModal] = useState(true);
  const url = LANDING_PAGE_FIRMAYA;
  const his = useHistory();
  const closeModal = () => {
    dispatch(modalSignatureReject(false));
    if (irhome) {
      localStorage.removeItem("URL_Circuito");
      his.push("/home");
    } else {
      window.open(url, "_self");
    }
    //return irhome ? his.push("/home") : window.open(url, "_self");
  };

  const [motivo, setMotivo] = useState("");

  const handleChange = (event) => {
    setMotivo(event.target.value);
  };

  return (
    <Modal open={modal} onClose={closeModal} disableBackdropClick>
      <div className={classes.modal}>
        <img src={rechazado} />
        <h4 className={classes.title}>¡Firma rechazada!</h4>
        <p className={classes.parrafo}>
          El creador del circuito de firmas será informado de tu decisión
        </p>
        <div className={classes.flex}>
          <button onClick={() => closeModal()} className={classes.continue}>
            Entendido
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalSignatureRejected;
